import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { Locale } from 'fabscale-app/models/enums/locales';
import { ResourceNotFoundError } from 'fabscale-app/models/errors/resource-not-found-error';
import createUserMutation from 'fabscale-app/gql/mutations/create-user.graphql';
import deactivateUserMutation from 'fabscale-app/gql/mutations/deactivate-user.graphql';
import impersonateUserMutation from 'fabscale-app/gql/mutations/impersonate-user.graphql';
import updateRoleForUserMutation from 'fabscale-app/gql/mutations/update-role-for-user.graphql';
import updateLocationsForUserMutation from 'fabscale-app/gql/mutations/update-user-locations.graphql';
import updateUserFrontendSettingsMutation from 'fabscale-app/gql/mutations/update-user-frontend-settings.graphql';
import updateUserMutation from 'fabscale-app/gql/mutations/update-user.graphql';
import currentUserQuery from 'fabscale-app/gql/queries/current-user.graphql';
import userByEmailQuery from 'fabscale-app/gql/queries/user-by-email.graphql';
import userInfoByIdQuery from 'fabscale-app/gql/queries/user-info-by-id.graphql';
import userInfosForCompanyQuery from 'fabscale-app/gql/queries/user-infos-for-company.graphql';
import usersForCompanyWithStatusQuery from 'fabscale-app/gql/queries/users-for-company-with-status.graphql';
import { Company } from 'fabscale-app/models/company';
import { Location, LocationInput } from 'fabscale-app/models/location';
import {
  User,
  UserFrontendSettingsInput,
  UserInput,
} from 'fabscale-app/models/user';
import { UserInfo, UserInfoInput } from 'fabscale-app/models/user-info';
import { UserRoleInput } from 'fabscale-app/models/user-role';
import GraphQLService from 'fabscale-app/services/-graphql';
import UserSessionService from 'fabscale-app/services/user-session';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import UserAuthenticationService from '../user-authentication';

export default class StoreUserService extends Service {
  @service userSession: UserSessionService;
  @service l10n: L10nService;
  @service userAuthentication: UserAuthenticationService;
  @service graphql: GraphQLService;

  get companyId() {
    return this.userSession.company!.id;
  }

  // METHODS
  async findByEmail(email: string): Promise<User> {
    let { graphql, l10n } = this;

    let variables = { email };

    try {
      let response = await graphql.query(
        { query: userByEmailQuery, variables, namespace: 'userByEmail' },
        { cacheEntity: 'User' }
      );
      return new User(response);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The user with email {{email}} could not be found.',
          {
            email,
          }
        );
      }

      throw error;
    }
  }

  async findInfoById(id: string): Promise<UserInfo> {
    let { graphql, l10n } = this;

    let variables = { id };

    try {
      let response: UserInfoInput = await graphql.query(
        { query: userInfoByIdQuery, variables, namespace: 'userInfoById' },
        { cacheEntity: 'User' }
      );

      return new UserInfo(response);
    } catch (error) {
      if (error instanceof ResourceNotFoundError) {
        error.translatedMessage = l10n.t(
          'The user with id {{id}} could not be found.',
          {
            id,
          }
        );
      }

      throw error;
    }
  }

  async findCurrent(
    email: string,
    defaultLocale: Locale
  ): Promise<{ user: User; company: Company; locations: Location[] }> {
    let { graphql } = this;

    let variables = { email };
    let response: {
      id: string;
      name: string;
      locale: Locale;
      role: UserRoleInput;
      frontendSettings?: UserFrontendSettingsInput;
      company: {
        id: string;
        name: string;
        features: { name: string }[];
        locations: { id: string }[];
      };
      locations: LocationInput[];
    } = await graphql.query(
      { query: currentUserQuery, variables, namespace: 'userByEmail' },
      {
        cacheEntity: 'User',
      }
    );

    let {
      id,
      name,
      locale,
      company: companyData,
      locations: locationsData,
      role,
      frontendSettings = {},
    } = response;

    const finalLocale = defaultLocale ? defaultLocale : locale;
    let user = new User({
      id,
      name,
      email,
      locale: finalLocale,
      role,
      frontendSettings,
    });

    let company = new Company({
      id: companyData.id,
      name: companyData.name,
      features: companyData.features.map((feature) => feature.name),
      locationIds: companyData.locations.map((location) => location.id),
    });

    let locations = locationsData.map((location) => {
      return new Location({
        id: location.id,
        name: location.name,
        timezoneName: location.timezoneName,
        settings: location.settings,
      });
    });

    return {
      user,
      company,
      locations,
    };
  }

  async findAll(): Promise<User[]> {
    let { graphql, companyId } = this;

    let variables = {
      companyId,
    };

    let users: UserInput[] = await graphql.query(
      {
        query: usersForCompanyWithStatusQuery,
        variables,
        namespace: 'usersForCompany',
      },
      { cacheEntity: 'User' }
    );

    return sortBy(users, 'name').map((userData) => new User(userData));
  }

  async findInfoAll(): Promise<UserInfo[]> {
    let { graphql, companyId } = this;

    let variables = {
      companyId,
    };

    let users: UserInfoInput[] = await graphql.query(
      {
        query: userInfosForCompanyQuery,
        variables,
        namespace: 'userInfosForCompany',
      },
      { cacheEntity: 'User' }
    );

    return sortBy(users, 'name').map(
      (userInfoData) => new UserInfo(userInfoData)
    );
  }

  async create({
    email,
    name,
    roleId,
    locale,
    locationIds,
  }: {
    email: string;
    name: string;
    locale: Locale;
    roleId: string;
    locationIds: string[];
  }): Promise<User> {
    let { graphql, companyId } = this;

    let variables = {
      input: {
        email,
        name,
        locale,
        companyId,
        locationIds,
        roleId,
      },
    };

    let user = await graphql.mutate(
      {
        mutation: createUserMutation,
        variables,
        namespace: 'createUser',
      },
      {
        invalidateCache: [{ cacheEntity: 'User' }],
      }
    );

    return new User(user);
  }

  async updateRoleForUser({
    userId,
    roleId,
  }: {
    userId: string;
    roleId: string;
  }): Promise<void> {
    let { graphql } = this;

    let variables = {
      userId,
      roleId,
    };

    await graphql.mutate(
      {
        mutation: updateRoleForUserMutation,
        variables,
      },
      {
        invalidateCache: [{ cacheEntity: 'User' }],
      }
    );
  }

  async updateLocationsForUser({
    userId,
    locationIds,
  }: {
    userId: string;
    locationIds: string[];
  }): Promise<void> {
    let { graphql } = this;

    let variables = {
      userId,
      locationIds,
    };

    await graphql.mutate(
      {
        mutation: updateLocationsForUserMutation,
        variables,
      },
      {
        invalidateCache: [{ cacheEntity: 'User' }],
      }
    );
  }

  async update(
    id: string,
    { name, locale }: { name?: string; locale?: Locale }
  ): Promise<{ id: string }> {
    let { graphql } = this;

    let variables: { id: string; name?: string; locale?: string } = {
      id,
    };

    if (name) {
      variables.name = name;
    }

    if (locale) {
      variables.locale = locale;
    }

    await graphql.mutate(
      {
        mutation: updateUserMutation,
        variables,
        namespace: 'updateUser',
      },
      {
        invalidateCache: [{ cacheEntity: 'User' }],
      }
    );

    return { id };
  }

  async updateFrontendSettings(
    userId: string,
    {
      dashboardConfig,
      kpiDataFilterTemplates,
      tableConfig,
      navigationConfig,
    }: {
      dashboardConfig?: string | null;
      kpiDataFilterTemplates?: string | null;
      tableConfig?: string | null;
      navigationConfig?: string | null;
    }
  ): Promise<void> {
    let { graphql } = this;

    assert(`Cannot update user settings without a userId`, !!userId);

    let variables = {
      id: userId,
      dashboardConfig,
      kpiDataFilterTemplates,
      tableConfig,
      navigationConfig,
    };

    await graphql.mutate({
      mutation: updateUserFrontendSettingsMutation,
      variables,
    });
  }

  async delete(email: string): Promise<void> {
    let { graphql } = this;

    let variables = {
      email,
    };

    await graphql.mutate(
      {
        mutation: deactivateUserMutation,
        variables,
      },
      {
        invalidateCache: [{ cacheEntity: 'User' }],
      }
    );
  }

  async impersonateUser(email: string): Promise<void> {
    let { graphql } = this;

    let user = await this.findByEmail(email);

    let variables = {
      userId: user.id,
    };

    await graphql.mutate({
      mutation: impersonateUserMutation,
      variables,
    });

    this.userAuthentication.saveCurrentUserData({
      email,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'store/user': StoreUserService;
  }
}
