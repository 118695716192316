import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

interface Args {
  id?: string;
  isChecked?: boolean;
  label: string;
  labelClass?: string;
  isDisabled?: boolean;
  onChange: (isChecked: boolean, { shiftKey }: { shiftKey?: boolean }) => void;
}

export default class UiCheckbox extends Component<Args> {
  inputId: string;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.inputId = this.args.id || guidFor(this);

    assert(
      '<Ui:Checkbox @onChange> onChange must be set',
      typeof this.args.onChange === 'function' || this.args.isDisabled
    );
  }

  @action
  handleOnClick(event: MouseEvent) {
    if (this.args.isDisabled) {
      return;
    }

    // We want to be able to know if shift was pressed while selecting a checkbox
    // This can be useful when selecting multiple rows in a list or similar
    // Sadly, we don't know this during "onChange", so we need to check "onClick"
    // We fall back to also checking "onChange" for full compatibility
    // Also sadly, on FF the change event is not triggered if clicking on a label with shift...
    // So we need to actually trigger the event here, to be cross browser compatible
    let isChecked = !this.args.isChecked;
    let { shiftKey } = event;

    this.args.onChange(isChecked, { shiftKey });

    event.preventDefault();
  }

  @action
  handleOnChange() {
    if (this.args.isDisabled) {
      return;
    }

    let isChecked = !this.args.isChecked;

    this.args.onChange(isChecked, { shiftKey: undefined });
  }
}
