import { DateTime } from 'luxon';
import { ContinuousDataType } from './enums/plant-asset-monitoring';
import { Unit } from 'fabscale-app/models/enums/units';
import { DateRange } from './date-range';
import { TimeRange } from './time-range';
import { tracked } from '@glimmer/tracking';
import { RelativeTimeframe } from './enums/relative-timeframe';

export interface PlantAssetIdNamePair {
  id: string;
  name: string;
}

export interface MonitoringSensor {
  type: ContinuousDataType;
  name: string;
  unit: Unit;
}

export interface TickableMonitoringSensor {
  type: ContinuousDataType;
  name: string;
  unit: Unit;
  isTicked: boolean;
  color?: string;
}

export interface ContinuousData {
  dateTime: DateTime;
  value: number;
}

export interface ContinuousDataItem {
  values: ContinuousData[];
  sensorType: ContinuousDataType;
  plantAssetId: string;
  unit: Unit;
  sensorName: string;
}

export interface ExtendedContinuousData {
  dateTime: DateTime;
  value: number;
  sensorName?: string;
  sensorType?: ContinuousDataType;
  unit?: Unit;
}
export interface ExtendedContinuousDataItem {
  values: ExtendedContinuousData[];
  sensorType: ContinuousDataType;
  plantAssetId: string;
  unit: Unit;
  sensorName: string;
  sensorColor?: string;
}

export interface PlantAssetSensorsItem {
  plantAsset: PlantAssetIdNamePair;
  monitoringSensors: MonitoringSensor[];
}

export interface ContinuousDataPayload {
  items: ContinuousDataItem[];
}

export interface MonitoringSensorsPayload {
  items: PlantAssetSensorsItem[];
}

export interface ContinuousDataFilters {
  dateFrom: DateTime;
  dateTo: DateTime;
  plantAssetIds: string[];
  sensorTypes: ContinuousDataType[];
}

export interface MonitoringSensorsFilters {
  locationId: string;
  dateFrom: DateTime;
  dateTo: DateTime;
}

export interface PlantAssetMonitoringFilters {
  dateRange: DateRange;
  timeRange: TimeRange;
  plantAssetId: string | undefined;
  sensorCategories: ContinuousDataType[];
  sensorNames: string[];
}

export class PlantAssetMonitoringFormData {
  @tracked dateRange: DateRange;
  @tracked relativeTimeframe?: RelativeTimeframe;
  @tracked timeRange: TimeRange;
  @tracked plantAssetId: string | undefined;
  @tracked sensorCategories: ContinuousDataType[];
  @tracked sensorNames: string[];
  @tracked templateName = '';
}
