import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { KpiCompareReportFilters } from 'fabscale-app/components/page/kpi-compare-report';
import {
  ALL_RELATIVE_TIMEFRAMES,
  RelativeTimeframe,
  RelativeTimeframeId,
} from 'fabscale-app/models/enums/relative-timeframe';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import { deserializeRelativeTimeframe } from 'fabscale-app/utilities/utils/serialize-relative-timeframe';

interface Args {
  filters: KpiCompareReportFilters;
  updateTemplate: (kpiFilterTemplate: KpiDataFilterTemplate) => void;
  updateRelativeTimeframe: (relativeTimeframe?: RelativeTimeframe) => void;
}

export default class UiFiltersTemplateSelection extends Component<Args> {
  @service kpiDataFilter: KpiDataFilterService;

  @tracked
  selectedRelativeTimeframeId: RelativeTimeframeId;

  availableTemplates: KpiDataFilterTemplate[];
  availableRelativeTimeframes: RelativeTimeframeId[] =
    ALL_RELATIVE_TIMEFRAMES.map((timeframe: RelativeTimeframe) => timeframe.id);

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
  }

  @action
  onChangeTemplate(selectedTemplate: KpiDataFilterTemplate) {
    this.args.updateTemplate(selectedTemplate);
  }

  @action
  onChangeTimeframe(relativeTimeframeId: RelativeTimeframeId) {
    const relativeTimeframe = relativeTimeframeId
      ? deserializeRelativeTimeframe(relativeTimeframeId)
      : undefined;

    this.args.updateRelativeTimeframe(relativeTimeframe);
    this.selectedRelativeTimeframeId = relativeTimeframeId;
  }
}
