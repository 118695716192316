import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { use } from 'ember-could-get-used-to-this';
import { isOeeKpi, KpiType } from 'fabscale-app/models/enums/kpi-types';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import LoadAvailableKpiTypesResource from 'fabscale-app/helpers/resources/load-available-kpi-types';
import LoadPlantAssetsInUseResource from 'fabscale-app/helpers/resources/load-plant-assets-in-use';
import LoadRecipesInUseResource from 'fabscale-app/helpers/resources/load-recipes-in-use';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import {
  OeeKpiReportFilterFormData,
  OeeKpiReportFilters,
} from 'fabscale-app/models/oee-kpi-report-models';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import PdfService from 'fabscale-app/services/pdf';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { relativeTimeframeToDateRange } from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { DateTime } from 'luxon';

interface Args {
  filters: OeeKpiReportFilters;
  updateFilters: (filters: OeeKpiReportFilters) => void;

  defaultTimeRange: TimeRange;
  canClose: boolean;
}

export default class PageOeeKpiReportFilters extends Component<Args> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @service kpiDataFilter: KpiDataFilterService;
  @service enumLabels: EnumLabelsService;
  @tracked label: string;
  @tracked formModel: FormDataModel<OeeKpiReportFilterFormData>;
  @tracked formData: OeeKpiReportFilterFormData;
  availableTemplates: KpiDataFilterTemplate[];
  @cached
  get exactDateRange(): DateRange | undefined {
    const { dateRange, timeRange } = this.formData;

    if (!dateRange || !dateRange.start || !dateRange.end) {
      return undefined;
    }

    return getExactDateRangeHelper({
      dateRange: dateRange as DateRange,
      timeRange,
      maxNow: true,
    });
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const {
      oeeKpiTypes,
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      recipeIds = [],
    } = this.args.filters;

    this._initFormModel({
      oeeKpiTypes,
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
    });

    this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    this.label =
      this.availableTemplates.length > 0
        ? 'Choose template or Time frame'
        : 'Choose timeframe';
  }

  @action
  updateDateRange(dateRange: DateRangeOptional) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.analytics.addEvent('oee-kpi-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: getDateRangeLength(dateRange),
      },
    ]);
  }

  printPageTask = dropTask(async () => {
    const fileName = `fabscale-oee-kpi-report-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    const isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    const {
      oeeKpiTypes,
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
      templateName,
    } = this.formData;

    const actualTimeRange =
      JSON.stringify(timeRange) === JSON.stringify(this.args.defaultTimeRange)
        ? undefined
        : timeRange;

    if (templateName) {
      const filterSettings = new KpiDataFilterSettings({
        kpiTypes: oeeKpiTypes,
        timeRange,
        plantAssetIds,
        recipeIds,
      });

      const kpiDataFilterTemplate = new KpiDataFilterTemplate({
        name: templateName,
        filterSettings,
      });

      await this.kpiDataFilter.addCustomTemplate(kpiDataFilterTemplate);
      this.analytics.addEvent('oee-kpi-report-template-add');
    }

    this.args.updateFilters({
      oeeKpiTypes,
      dateRange,
      timeRange: actualTimeRange,
      plantAssetIds,
      recipeIds,
    });

    return true;
  });

  @action
  clearFilters() {
    this._initFormModel({
      dateRange: undefined,
      timeRange: this.args?.defaultTimeRange,
      oeeKpiTypes: [],
      plantAssetIds: [],
      recipeIds: [],
    });
  }

  @action
  resetFilters() {
    const {
      oeeKpiTypes,
      dateRange,
      timeRange = this.args?.defaultTimeRange,
      plantAssetIds = [],
      recipeIds = [],
    } = this.args.filters;

    this._initFormModel({
      oeeKpiTypes,
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
    });
  }

  @action
  updateOeeKpiTypes(oeeKpiTypes: KpiType[]) {
    this.formModel.updateProperty('oeeKpiTypes', oeeKpiTypes);

    this.analytics.addEvent('oee-kpi-filter-kpi-type', [
      { name: 'kpiTypeCount', value: oeeKpiTypes.length },
    ]);
  }

  @action
  updatePlantAssets(plantAssets: PlantAsset[]) {
    const plantAssetIds = plantAssets.map((plantAsset) => plantAsset.id);
    this.formModel.updateProperty('plantAssetIds', plantAssetIds);

    this.analytics.addEvent('oee-kpi-filter-plant-assets', [
      { name: 'selectedCount', value: plantAssets.length },
    ]);
  }

  @action
  updateRecipes(recipes: { id: string; name: string }[]) {
    const recipeIds = recipes.map((recipe) => recipe.id);
    this.formModel.updateProperty('recipeIds', recipeIds);

    this.analytics.addEvent('oee-kpi-filter-recipes', [
      { name: 'selectedCount', value: recipes.length },
    ]);
  }

  @action
  onSelectTemplate(template: KpiDataFilterTemplate) {
    const {
      relativeTimeframe,
      absoluteTimeframe,
      timeRange,
      kpiTypes: oeeKpiTypes,
      plantAssetIds,
      recipeIds,
    } = template.filterSettings;

    this.formData.relativeTimeframe = relativeTimeframe;
    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe)
      : absoluteTimeframe;

    this.formData.timeRange =
      timeRange && timeRange.start && timeRange.end
        ? (timeRange as TimeRange)
        : this.args.defaultTimeRange;

    this.formData.oeeKpiTypes = oeeKpiTypes;
    this.formData.plantAssetIds = plantAssetIds || [];
    this.formData.recipeIds = recipeIds || [];
  }

  @action
  onSelectRelativeTimeframe(relativeTimeframe?: RelativeTimeframe) {
    this.formData.relativeTimeframe = relativeTimeframe;
    const { timeRange } = this.formData;

    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe, timeRange)
      : { start: undefined, end: undefined };
  }

  @use
  availableOeeKpiTypesAsyncResource = new LoadAvailableKpiTypesResource(() => [
    {},
  ]);

  @use
  allPlantAssetsAsyncResource = new LoadPlantAssetsInUseResource(() => [
    { dateRange: this.exactDateRange },
  ]);

  @use
  allRecipesAsyncResource = new LoadRecipesInUseResource(() => [
    { dateRange: this.exactDateRange, pageSize: 999 },
  ]);

  get allPlantAssets() {
    return getAsyncResourceData(this.allPlantAssetsAsyncResource, []);
  }

  get availableOeeKpiTypes() {
    return getAsyncResourceData(
      this.availableOeeKpiTypesAsyncResource,
      []
    ).filter((oeeKpiType) => isOeeKpi(oeeKpiType));
  }

  get selectedPlantAssets() {
    const { allPlantAssets } = this;
    const { plantAssetIds } = this.formData;

    if (!allPlantAssets || !plantAssetIds) {
      return [];
    }

    return allPlantAssets.filter((plantAsset) =>
      plantAssetIds!.includes(plantAsset.id)
    );
  }

  get allRecipes() {
    return getAsyncResourceData(this.allRecipesAsyncResource, []);
  }

  get selectedRecipes() {
    const { allRecipes } = this;
    const { recipeIds } = this.formData;

    if (!allRecipes || !recipeIds) {
      return [];
    }

    return allRecipes.filter((recipe) => recipeIds!.includes(recipe.id));
  }

  get activeFilters(): { label: string; value: string }[] {
    const { l10n, enumLabels } = this;

    const dateStart = this.args.filters?.dateRange?.start;
    const dateEnd = this.args.filters?.dateRange?.end;

    const filters = [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('KPIs'),
        value: this.formData.oeeKpiTypes
          .map((oeeKpiType) => enumLabels.kpiType(oeeKpiType))
          .join(', '),
      },
      {
        label: l10n.t('Roasters'),
        value:
          this.selectedPlantAssets.length > 0
            ? this.selectedPlantAssets
                .map((plantAsset) => plantAsset.name)
                .join(', ')
            : l10n.t('All roasters'),
      },
      {
        label: l10n.t('Recipes'),
        value:
          this.selectedRecipes.length > 0
            ? this.selectedRecipes.map((recipe) => recipe.name).join(', ')
            : l10n.t('All recipes'),
      },
    ];

    return filters;
  }

  _initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    timeRange: TimeRangeOptional;
    plantAssetIds: string[];
    recipeIds: string[];
    oeeKpiTypes?: KpiType[];
  }) {
    const { l10n } = this;
    const { oeeKpiTypes, dateRange, timeRange, recipeIds, plantAssetIds } =
      initialData;

    this.formData = new OeeKpiReportFilterFormData();

    Object.assign(this.formData, {
      oeeKpiTypes,
      dateRange,
      timeRange,
      recipeIds,
      plantAssetIds,
    });

    this.formModel = new FormDataModel<OeeKpiReportFilterFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRangeOptional | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },
          message: l10n.t('You have to select a date range.'),
        },
        {
          propertyName: 'oeeKpiTypes',
          validate(value: KpiType[]) {
            return value?.length > 0;
          },
          message: l10n.t('You have to select at least one KPI.'),
        },
      ],
    });
  }
}
