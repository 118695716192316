export function generateHtmlSnapshot(
  element = document.documentElement
): string {
  let html = element.innerHTML;

  let host = window.location.origin;
  let pathName = window.location.pathname;

  let el = document.createElement('html');
  el.innerHTML = html;

  // Remove JS
  el.querySelectorAll('script').forEach((script) => {
    script!.parentElement!.removeChild(script);
  });

  // Ensure CSS paths & images are absolute
  el.querySelectorAll('img').forEach((img) => {
    let src = img.getAttribute('src') || '';
    let newSrc = fixPath(src, { host, pathName });

    if (newSrc) {
      img.setAttribute('src', newSrc);
    }
  });

  el.querySelectorAll('link[rel="stylesheet"]').forEach((linkRel) => {
    let href = linkRel.getAttribute('href') || '';
    let newHref = fixPath(href, { host, pathName });

    if (newHref) {
      linkRel.setAttribute('href', newHref);
      linkRel.removeAttribute('integrity');
    }

    // Also remove integrity fields
    linkRel.removeAttribute('integrity');
  });

  return el.outerHTML;
}

function fixPath(
  path: string,
  { host, pathName }: { host: string; pathName: string }
): string | null {
  if (path.startsWith('http')) {
    return null;
  }

  return path.startsWith('/') ? `${host}${path}` : `${host}${pathName}/${path}`;
}
