import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import {
  configIsKpiCard,
  DashboardCard,
  DashboardCardConfig,
  DashboardCardPosition,
  DashboardCardType,
} from 'fabscale-app/models/dashboard-config';
import DashboardConfigManagerService from 'fabscale-app/services/dashboard-config-manager';
import ScreenService from 'fabscale-app/services/screen';
import {
  addRowOnTop,
  cardHasNoOverlap,
  moveCardToPos,
} from 'fabscale-app/utilities/utils/dashboard/reorder-cards';
import { scrollToTop } from 'fabscale-app/utilities/utils/dom/scroll-to-top';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Signature {
  Args: {
    isDefault: boolean;
    dashboardCards: DashboardCard[];
    onUpdateCards: () => void;
  };
}

export const DASHBOARD_COLUMN_COUNT = 4;

export default class PageDashboardCustomizeCardList extends Component<Signature> {
  @service dashboardConfigManager: DashboardConfigManagerService;
  @service router: RouterService;
  @service screen: ScreenService;

  @tracked _dashboardCards: DashboardCard[] | undefined;
  @tracked _isDefault: boolean | undefined;
  @tracked isDragging = false;

  @tracked hasError = false;

  private cardYxCompare(a: DashboardCard, b: DashboardCard): number {
    return a.y === b.y ? a.x - b.x : a.y - b.y;
  }

  get isDefault() {
    return typeof this._isDefault === 'boolean'
      ? this._isDefault
      : this.args.isDefault;
  }

  get dashboardCards() {
    return this._dashboardCards || this.args.dashboardCards;
  }

  get isCompact() {
    return this.screen.hasMobileMenu;
  }

  // We dynamically generate new IDs - these are not saved anywhere
  // So they only need to remain consistent in this session
  get maxId() {
    return Math.max(0, ...this.dashboardCards.map((card) => card.id));
  }

  get emptyCards() {
    let { dashboardCards, maxId } = this;

    let emptyCards: DashboardCardPosition[] = [];

    // We always want to show two more rows than are currently filled
    let maxY =
      Math.max(0, ...dashboardCards.map((card) => card.y + card.height)) + 1;

    for (let y = 0; y <= maxY; y++) {
      for (let x = 0; x < DASHBOARD_COLUMN_COUNT; x++) {
        let tmpCard: DashboardCardPosition = {
          x,
          y,
          width: 1,
          height: 1,
          id: ++maxId,
        };

        if (cardHasNoOverlap(tmpCard, dashboardCards)) {
          emptyCards.push(tmpCard);
        }
      }
    }

    return emptyCards;
  }

  get hasChanges() {
    return !!this._dashboardCards;
  }

  get canRemoveTopRow() {
    return (
      !this.dashboardCards.some((card) => card.y === 0) &&
      this.dashboardCards.length > 0
    );
  }

  willDestroy(): void {
    this._resetRouteHandler();

    super.willDestroy();
  }

  _beforeRouteChangeHandler?: (transition: Transition) => void;

  @action
  updateConfig(cardId: number, cardConfig: DashboardCardConfig) {
    let dashboardCards = this.dashboardCards.slice();

    let cardPos = dashboardCards.findIndex((card) => card.id === cardId);

    if (cardPos < 0) {
      logError(`Could not find dashboard card with id ${cardId} to update.`);
      return;
    }

    let card = dashboardCards[cardPos]!;

    assert(
      `Existing card type "${card.config.cardType}" matches updated card type "${cardConfig.cardType}"`,
      card.config.cardType === cardConfig.cardType
    );

    let newCard = Object.assign({}, card, { config: cardConfig });

    // Replace card with new card, so we do not modify the existing ones
    dashboardCards.splice(cardPos, 1, newCard);

    this._dashboardCards = dashboardCards;
    this._isDefault = false;

    this._addRouteHandler();
  }

  @action
  startDrag() {
    this.isDragging = true;
  }

  @action
  endDrag() {
    this.isDragging = false;
  }

  @action
  addRowOnTop() {
    let newDashboardCards = addRowOnTop(this.dashboardCards);

    this._dashboardCards = newDashboardCards;
    this._isDefault = false;

    this._addRouteHandler();
  }

  @action
  removeRowOnTop() {
    while (this.canRemoveTopRow) {
      let newDashboardCards = this.dashboardCards.map((card) => {
        return Object.assign({}, card, { y: card.y - 1 });
      });

      this._dashboardCards = newDashboardCards;
      this._isDefault = false;

      this._addRouteHandler();
    }
  }

  @action
  clear() {
    this._dashboardCards = [];
    this._isDefault = false;

    this._addRouteHandler();
  }

  @action
  moveCard(id: number, pos: { x: number; y: number }) {
    let { dashboardCards } = this;
    let cardPos = dashboardCards.findIndex((card) => card.id === id);

    if (cardPos < 0) {
      logError(`Could not find dashboard card with id ${id} to move.`);
      return;
    }

    let newDashboardCards = moveCardToPos(this.dashboardCards, id, pos);

    if (newDashboardCards) {
      this._dashboardCards = newDashboardCards;
      this._isDefault = false;

      this._addRouteHandler();
    }
  }

  @action
  addCard({
    cardType,
    x,
    y,
    width,
    height,
  }: {
    cardType: DashboardCardType;
    x: number;
    y: number;
    width: number;
    height: number;
  }) {
    let id = this.maxId + 1;

    let newCard: DashboardCard = {
      x,
      y,
      width,
      height,
      id,
      config: { cardType },
    };

    let cards = this.dashboardCards.slice();
    cards.push(newCard);

    if (!this._canMove(newCard, cards)) {
      return;
    }

    this._dashboardCards = cards;
    this._isDefault = false;

    this._addRouteHandler();
  }

  @action
  validateCardConfig(config: DashboardCardConfig) {
    if (configIsKpiCard(config) && !config.kpiType) {
      return false;
    }

    return true;
  }

  _validate() {
    let hasError = this.dashboardCards.some(
      (card) => !this.validateCardConfig(card.config)
    );

    this.hasError = hasError;

    if (hasError) {
      scrollToTop();
    }

    return hasError;
  }

  @action
  saveDashboard() {
    if (!this.hasChanges) {
      return;
    }

    if (this._validate()) {
      return;
    }

    const cards = this._isDefault
      ? undefined
      : this.dashboardCards.sort(this.cardYxCompare);

    this.dashboardConfigManager.saveDashboardCards(cards);
    this._dashboardCards = undefined;
    this._resetRouteHandler();

    this.args.onUpdateCards();
  }

  @action
  discardChanges() {
    this._dashboardCards = undefined;
    this._isDefault = undefined;
    this.hasError = false;
    this._resetRouteHandler();
  }

  resetDefaultsTask = dropTask(async () => {
    let defaultCards: DashboardCard[] =
      await this.dashboardConfigManager.getDefaultDashboardCards();

    this._dashboardCards = defaultCards;
    this._isDefault = true;
    this.hasError = false;
  });

  @action
  deleteCard(cardId: number) {
    let dashboardCards = this.dashboardCards.slice();

    let cardPos = dashboardCards.findIndex((card) => card.id === cardId);

    if (cardPos < 0) {
      logError(`Could not find dashboard card with id ${cardId} to delete.`);
      return;
    }

    dashboardCards.splice(cardPos, 1);

    this._dashboardCards = dashboardCards;
    this._isDefault = false;

    this._addRouteHandler();
  }

  _addRouteHandler() {
    if (!this._beforeRouteChangeHandler) {
      this._beforeRouteChangeHandler = (transition: Transition) => {
        // When we abort, it triggers a new transition to the same route
        // Which leads to an endless loop if we don't handle that here
        if (transition.from?.name === transition?.to.name) {
          return;
        }

        if (!window.confirm('You have unsaved changes! Are you sure?')) {
          transition.abort();
        }
      };

      this.router.on('routeWillChange', this._beforeRouteChangeHandler);
    }
  }

  _resetRouteHandler() {
    if (this._beforeRouteChangeHandler) {
      this.router.off('routeWillChange', this._beforeRouteChangeHandler);
      this._beforeRouteChangeHandler = undefined;
    }
  }

  _canMove(card: DashboardCard, cards: DashboardCard[]) {
    if (card.x + card.width > DASHBOARD_COLUMN_COUNT) {
      return false;
    }

    if (card.y < 0 || card.x < 0) {
      return false;
    }

    return cardHasNoOverlap(card, cards);
  }
}
