import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { ChartData, ChartOptions } from 'chart.js';

interface Args {
  chartData: ChartData;
  chartOptions: ChartOptions;
}

export default class PageKpiDetailsReportPerMachineOverTimeChart extends Component<Args> {
  @tracked currentOffset = 0;
  private pageSize = 12;

  get chartData() {
    const { chartData } = this.args;

    return chartData;
  }

  get paginatedData() {
    const { currentOffset } = this;

    let paginatedData = JSON.parse(JSON.stringify(this.chartData));

    paginatedData.datasets.forEach((dataset: any) => {
      dataset.data = dataset.data.splice(currentOffset, this.pageSize);
    });

    return paginatedData;
  }

  get chartOptions() {
    const { chartOptions } = this.args;

    return chartOptions;
  }

  get roastersBannerData() {
    const { chartData } = this;

    const info = chartData?.datasets.map((item: any) => {
      return {
        color: item.backgroundColor,
        name: item.label,
      };
    });

    return info;
  }

  get totalItems() {
    const { chartData } = this.args;

    if (!chartData || !chartData.datasets || !chartData.datasets.length) {
      return 0;
    }

    return Math.max(
      ...chartData.datasets.map((dataset: any) => dataset.data.length)
    );
  }

  get showPagination() {
    return (
      this.chartData.datasets.length &&
      this.chartData.datasets[0]!.data.length > this.pageSize
    );
  }

  @action
  moveTo(currentOffset: number) {
    this.currentOffset = currentOffset;
  }
}
