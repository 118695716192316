import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import ScreenService, { ScreenCallback } from 'fabscale-app/services/screen';

interface Args {
  data: ChartData;
  options: ChartOptions;
  plugins: Plugin[];
}

export default class PageStatusMapReportPerPlantAssetChart extends Component<Args> {
  @service screen: ScreenService;
  @tracked currentOffset = 0;
  @tracked widthCategory: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

  private callback?: ScreenCallback;

  constructor(owner: any, args: any) {
    super(owner, args);

    const callback = () => {
      this.widthCategory = this.screen.widthCategory;
    };

    this.screen.addWidthListener(callback);
    this.callback = callback;
  }

  private pageSizeByWidthCategory(
    widthCategory: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'
  ) {
    switch (widthCategory) {
      case 'xsmall':
        return 1;
      case 'small':
        return 2;
      case 'medium':
        return 3;
      case 'large':
        return 5;
      case 'xlarge':
        return 5;
      default:
        return 0;
    }
  }

  get totalItems() {
    const { data } = this.args;

    if (!data || !data.datasets || !data.datasets.length) {
      return 0;
    }

    return Math.max(...data.datasets.map((dataset) => dataset.data.length));
  }

  get pageSize() {
    return this.widthCategory
      ? this.pageSizeByWidthCategory(this.widthCategory)
      : this.pageSizeByWidthCategory(this.screen.widthCategory);
  }

  get data() {
    const { data } = this.args;

    return data;
  }

  get paginatedData() {
    const { currentOffset, pageSize } = this;

    let paginatedData = JSON.parse(JSON.stringify(this.data));

    paginatedData.datasets.forEach((dataset: any) => {
      dataset.data = dataset.data.splice(currentOffset, pageSize);
    });

    return paginatedData;
  }

  get roastersBannerData() {
    const { data } = this;

    const info = data?.datasets.map((item: any) => {
      return {
        color: item.backgroundColor,
        name: (item as any).data[0]?.label,
      };
    });

    return info;
  }

  get options() {
    const { options } = this.args;

    return options;
  }

  get showPagination() {
    return (
      this.data.datasets.length &&
      this.data.datasets[0]!.data.length > this.pageSize
    );
  }

  @action
  moveTo(currentOffset: number) {
    this.currentOffset = currentOffset;
  }

  willDestroy() {
    if (this.callback) {
      this.screen.removeWidthListener(this.callback);
    }

    super.willDestroy();
  }
}
