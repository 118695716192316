import Component from '@glimmer/component';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { PlantAlarmOverTime } from 'fabscale-app/models/alarm-report';

interface Args {
  plantAlarmsOvertime: PlantAlarmOverTime[];
  data: ChartData;
  options: ChartOptions;
  plugins: Plugin[];
}

export default class PageAlarmReportLevelReportPerPlantAssetChartsAlarmLevel extends Component<Args> {
  get data() {
    const { data } = this.args;

    return data;
  }

  get options() {
    const { options } = this.args;

    return options;
  }

  get plugins() {
    const { plugins } = this.args;

    return plugins;
  }

  get roastersBannerData() {
    const { data } = this.args;

    const info = data?.datasets?.map((dataset) => {
      return {
        color: dataset.borderColor,
        name: (dataset.data[0] as any).idNamePair.name,
      };
    });

    return info;
  }
}
