import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { KpiCompareReportFilters } from 'fabscale-app/components/page/kpi-compare-report';
import {
  ALL_RELATIVE_TIMEFRAMES,
  RelativeTimeframe,
  RelativeTimeframeId,
} from 'fabscale-app/models/enums/relative-timeframe';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import {
  deserializeRelativeTimeframe,
  serializeRelativeTimeframe,
} from 'fabscale-app/utilities/utils/serialize-relative-timeframe';

interface Args {
  filters: KpiCompareReportFilters;
  updateTemplate: (kpiFilterTemplate: KpiDataFilterTemplate) => void;
  updateRelativeTimeframe: (relativeTimeframe?: RelativeTimeframe) => void;
}

export default class PageOeeKpiReportFiltersTemplateSelection extends Component<Args> {
  @service kpiDataFilter: KpiDataFilterService;

  availableTemplates: KpiDataFilterTemplate[];

  availableRelativeTimeframes: RelativeTimeframeId[] =
    ALL_RELATIVE_TIMEFRAMES.map((timeframe) => timeframe.id);

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.availableTemplates = this.kpiDataFilter.getOeeAvailableTemplates();
  }

  get selectedRelativeTimeframeId() {
    const { relativeTimeframe } = this.args.filters;
    return relativeTimeframe
      ? serializeRelativeTimeframe(relativeTimeframe)
      : undefined;
  }

  @action
  onChangeTemplate(selectedTemplate: KpiDataFilterTemplate) {
    this.args.updateTemplate(selectedTemplate);
  }

  @action
  onChangeTimeframe(relativeTimeframeId: RelativeTimeframeId | undefined) {
    const relativeTimeframe = relativeTimeframeId
      ? deserializeRelativeTimeframe(relativeTimeframeId)
      : undefined;

    this.args.updateRelativeTimeframe(relativeTimeframe);
  }
}
