import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { Permission } from 'fabscale-app/models/enums/permissions';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { UserRole } from 'fabscale-app/models/user-role';
import ErrorParserService from 'fabscale-app/services/error-parser';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Args {
  role?: UserRole;
  onSubmit: (data: {
    name: string;
    permissions: Permission[];
  }) => Promise<void>;
}

class FormData {
  @tracked name = '';
  @tracked permissions: Permission[] = [];
}

export default class PageSettingsRolesForm extends Component<Args> {
  @service l10n: L10nService;
  @service('error-parser') errorParser: ErrorParserService;
  @service router: RouterService;

  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;

  get isNew() {
    return typeof this.args.role === 'undefined';
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initialiseFormData();
  }

  submitFormTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return;
    }

    let { name, permissions } = this.formData;

    try {
      await this.args.onSubmit({ name, permissions });
    } catch (error) {
      logError(error);
      this.formModel.addError(this.errorParser.getErrorMessage(error));
      return;
    }

    if (!this.isNew) {
      this.router.transitionTo(
        'routes/company-settings.roles.show.index',
        this.args.role!.id
      );
      return;
    }

    this._initialiseFormData();
  });

  @action
  cancel() {
    if (this.isNew) {
      this.router.transitionTo('routes/company-settings.roles.index');
    } else {
      this.router.transitionTo('routes/company-settings.roles.show.index');
    }
  }

  @action
  prefillForRoleTemplate(role: UserRole) {
    this.formModel.updateProperty('permissions', role.permissions.slice());
  }

  _initialiseFormData() {
    let { l10n } = this;

    let formData = new FormData();

    if (this.args.role) {
      let { name, permissions } = this.args.role;
      formData.name = name;
      formData.permissions = permissions.slice();
    }

    this.formData = formData;
    this.formModel = new FormDataModel({
      data: formData,
      validations: [
        {
          propertyName: 'name',
          validate: (value?: string) => !!value,
          message: l10n.t('You have to enter a name.'),
        },
      ],
    });
  }
}
