import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { SuccessMessage } from 'fabscale-app';
import {
  Locale,
  LocaleLabelPair,
  LocaleLabels,
} from 'fabscale-app/models/enums/locales';
import { UniqueConstraintError } from 'fabscale-app/models/errors/unique-constraint-error';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { Location } from 'fabscale-app/models/location';
import { User } from 'fabscale-app/models/user';
import { UserRole } from 'fabscale-app/models/user-role';
import ErrorParserService from 'fabscale-app/services/error-parser';
import UserSessionService from 'fabscale-app/services/user-session';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import { logError } from 'fabscale-app/utilities/utils/log-error';

export interface UserFormData {
  id?: string;
  name: string;
  locale: Locale;
  email: string;
  roleId: string;
  locationIds: string[];
}

interface Args {
  user?: User;
  onSubmit: (data: UserFormData) => Promise<void>;
}

export default class ModuleUserForm extends Component<Args> {
  @service l10n: L10nService;
  @service router: RouterService;
  @service userSession: UserSessionService;
  @service('error-parser') errorParser: ErrorParserService;

  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;
  @tracked successMessage?: SuccessMessage;

  availableLocales = sortBy(LocaleLabels, 'label');

  get availableLocations() {
    return this.userSession.locations;
  }

  get selectedLocations() {
    let { locationIds } = this.formData;
    return this.availableLocations.filter((location) =>
      locationIds.includes(location.id)
    );
  }

  get isNew() {
    return !this.args.user;
  }

  get userIsCurrentUser() {
    return !this.isNew && this.args.user!.id === this.userSession.user!.id;
  }

  get selectedLocale() {
    let { locale } = this.formData;

    return locale
      ? this.availableLocales.find((localeData) => localeData.locale === locale)
      : undefined;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initialiseFormData();
  }

  @action
  updateEmail(email: string) {
    this.formData.email = email;
    this.formModel.validatePropertyDebouncedTask.perform('email', 1000);

    this.formModel.hasChanges = true;
  }

  @action
  updateName(name: string) {
    this.formModel.updateProperty('name', name);
  }

  @action
  updateLocale(localeData: LocaleLabelPair) {
    this.formModel.updateProperty('locale', localeData.locale);
  }

  @action
  updateRole(role: UserRole) {
    this.formModel.updateProperty('roleId', role.id);
  }

  @action
  updateLocations(locations: Location[]) {
    this.formModel.updateProperty(
      'locationIds',
      locations.map((location) => location.id)
    );
  }

  @action
  cancel() {
    if (this.isNew) {
      this.router.transitionTo('routes/company-settings.users.index');
    } else {
      this.router.transitionTo(
        'routes/.users.show.index',
        this.args.user!.email
      );
    }
  }

  submitFormTask = dropTask(async () => {
    let { formData } = this;

    this.successMessage = undefined;

    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let data: UserFormData = {
      id: formData.id,
      name: formData.name!,
      roleId: formData.roleId!,
      email: formData.email!,
      locale: formData.locale!,
      locationIds: formData.locationIds,
    };

    try {
      await this.args.onSubmit(data);
    } catch (error) {
      this._handleError(error);
      return;
    }

    if (!this.isNew) {
      this.router.transitionTo(
        'routes/company-settings.users.show.index',
        this.args.user!.email
      );
      return;
    }

    let userEmail = formData.email!;
    let userName = formData.name!;

    let { l10n } = this;
    this.successMessage = {
      title: l10n.t('User invitation sent'),
      description: l10n.t(
        'We have sent an invitation email to {{userEmail}}. {{userName}} needs to follow the instructions from the email to setup their account.',
        { userEmail, userName }
      ),
    };

    this._initialiseFormData();
  });

  _handleError(error: any) {
    let { l10n } = this;

    if (error instanceof UniqueConstraintError) {
      this.formModel.addError(
        l10n.t('A user with this email address already exists.'),
        'email'
      );
      return;
    }

    logError(error);
    this.formModel.addError(this.errorParser.getErrorMessage(error));
  }

  _initialiseFormData() {
    let { l10n } = this;

    let formData = new FormData();
    this.formData = formData;

    let { user } = this.args;
    if (user) {
      formData.id = user.id;
      formData.name = user.name;
      formData.locale = user.locale;
      formData.email = user.email;
      formData.roleId = user.role.id;
      formData.locationIds = user.locations.map((location) => location.id);
    } else {
      formData.locale = this.userSession.user!.locale;
      formData.locationIds = this.userSession.locations.map(
        (location) => location.id
      );
    }

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'email',
          message: l10n.t('You must enter a valid email address.'),
          validate: (value) => value && value.includes('@'),
        },
        {
          propertyName: 'name',
          message: l10n.t('You must enter a full name.'),

          validate: (value) => value,
        },
        {
          propertyName: 'roleId',
          message: l10n.t('You must select a role.'),

          validate: (value) => value,
        },
      ],
    });
  }
}

class FormData {
  id?: string;
  @tracked email?: string;
  @tracked name?: string;
  @tracked locale?: Locale;
  @tracked roleId?: string;
  @tracked locationIds: string[] = [];
}
