import { action } from '@ember/object';
import Component from '@glimmer/component';
import { ChartConfiguration, ChartData, Plugin, ChartOptions } from 'chart.js';
import { Chart } from 'chart.js/auto';
import { BarChart } from 'fabscale-app/models/bar-chart';

interface UiBarChartArgs {
  id: string;
  data: ChartData;
  options?: ChartOptions;
  plugins?: Plugin[];
}

export default class UiBarChart extends Component<UiBarChartArgs> {
  private _chart: Chart;

  @action
  onInit() {
    const { id, data, options, plugins } = this.args;
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>(
      document.getElementById(id)
    );
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (ctx) {
      const config: ChartConfiguration = new BarChart(data, options, plugins)
        .config;

      this._chart = new Chart(ctx, config);
    }
  }

  @action
  updateChart() {
    const { data } = this.args;

    this._chart.data = data;
    this._chart.update();
  }
}
