import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency';
import { use } from 'ember-could-get-used-to-this';
import {
  PlantAssetType,
  PlantAssetTypes,
} from 'fabscale-app/models/enums/plant-asset-types';
import {
  RecipeIdentificationStrategies,
  RecipeIdentificationStrategy,
} from 'fabscale-app/models/enums/recipe-identification-strategies';
import LoadMOCK_PLANT_ASSET_MODELSResource from 'fabscale-app/helpers/resources/load-available-plant-asset-models';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { PlantAssetModel } from 'fabscale-app/models/plant-asset-model';
import ErrorParserService from 'fabscale-app/services/error-parser';
import StorePlantAssetService from 'fabscale-app/services/store/plant-asset';
import UserSessionService from 'fabscale-app/services/user-session';
import { chartColors } from 'fabscale-app/utilities/fixtures/chart-colors';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { logError } from 'fabscale-app/utilities/utils/log-error';

interface Args {
  plantAsset?: PlantAsset;
  onSubmit: ({
    name,
    type,
    modelIdentifier,
    year,
    position,
    referenceNumber,
    displayColor,
    idPerLocation,
    recipeIdentificationStrategy,
  }: {
    name: string;
    type: PlantAssetType;
    modelIdentifier?: string;
    year?: string;
    position?: string;
    referenceNumber?: string;
    displayColor?: string;
    idPerLocation?: string;
    recipeIdentificationStrategy?: RecipeIdentificationStrategy;
  }) => void;
}

export default class PlantAssetForm extends Component<Args> {
  @service l10n: L10nService;
  @service router: RouterService;
  @service userSession: UserSessionService;
  @service('error-parser') errorParser: ErrorParserService;
  @service('store/plant-asset') plantAssetStore: StorePlantAssetService;

  @tracked formData: FormData;
  @tracked formModel: FormDataModel<FormData>;

  availableTypes = PlantAssetTypes;
  availableRecipeIdentificationStrategy = RecipeIdentificationStrategies;

  @use
  allPlantAssetModelsResource = new LoadMOCK_PLANT_ASSET_MODELSResource(() => [
    {},
  ]);

  get allPlantAssetModels() {
    return getAsyncResourceData(this.allPlantAssetModelsResource, []);
  }

  get availablePlantAssetModels() {
    let { allPlantAssetModels } = this;
    let { type } = this.formData;

    if (!type) {
      return [];
    }

    return allPlantAssetModels.filter((model) => {
      return !model.plantAssetType || model.plantAssetType === type;
    });
  }

  get selectedPlantAssetModel() {
    let { modelIdentifier } = this.formData;

    if (!modelIdentifier) {
      return undefined;
    }

    return this.allPlantAssetModels.find(
      (model) => model.identifier === modelIdentifier
    );
  }

  get isNew() {
    return !this.args.plantAsset;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this._initialiseFormData();
  }

  @action
  updateName(name: string) {
    this.formModel.updateProperty('name', name);
  }

  @action
  updateType(type: PlantAssetType) {
    this.formModel.updateProperty('type', type);
    this.formModel.updateProperty('modelIdentifier', undefined);
  }

  @action
  updateModel(model?: PlantAssetModel) {
    this.formModel.updateProperty('modelIdentifier', model?.identifier);
  }

  @action
  updateYear(year: string) {
    this.formModel.updateProperty('year', year);
  }

  @action
  updateReferenceNumber(referenceNumber: string) {
    this.formModel.updateProperty('referenceNumber', referenceNumber);
  }

  @action
  updateDisplayColor(displayColor?: string) {
    this.formModel.updateProperty('displayColor', displayColor);
  }

  @action
  updatePosition(position: string) {
    this.formModel.updateProperty('position', position);
  }

  @action
  updateRecipeIdentificationStrategy(
    recipeIdentificationStrategy?: RecipeIdentificationStrategy
  ) {
    this.formModel.updateProperty(
      'recipeIdentificationStrategy',
      recipeIdentificationStrategy
    );
  }

  @action
  updateIdPerLocation(idPerLocation: string) {
    this.formModel.updateProperty('idPerLocation', idPerLocation);
  }

  @action
  cancel() {
    if (this.isNew) {
      this.router.transitionTo('routes/plant-settings.plant-assets.index');
    } else {
      this.router.transitionTo(
        'routes/plant-settings.plant-assets.show',
        this.args.plantAsset!.id
      );
    }
  }

  submitFormTask = dropTask(async () => {
    let { formData } = this;

    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let {
      name,
      modelIdentifier,
      year,
      position,
      referenceNumber,
      displayColor,
      idPerLocation,
      type,
      recipeIdentificationStrategy,
    } = formData;

    try {
      await this.args.onSubmit({
        name: name!,
        type: type!,
        modelIdentifier,

        year,
        position,
        referenceNumber,
        displayColor,
        idPerLocation,
        recipeIdentificationStrategy,
      });
    } catch (error) {
      this._handleError(error);
      return;
    }

    this._initialiseFormData();
  });

  _handleError(error: any) {
    logError(error);
    this.formModel.addError(this.errorParser.getErrorMessage(error));
  }

  _initialiseFormData() {
    let { l10n } = this;

    let formData = new FormData();
    this.formData = formData;

    let { plantAsset } = this.args;
    if (plantAsset) {
      formData.id = plantAsset.id;
      formData.name = plantAsset.name;
      formData.type = plantAsset.type;
      formData.modelIdentifier = plantAsset.model?.identifier;
      formData.year = plantAsset.year;
      formData.referenceNumber = plantAsset.referenceNumber;
      formData.displayColor = plantAsset.displayColor;
      formData.position = plantAsset.position;
      formData.idPerLocation = plantAsset.idPerLocation;
      formData.recipeIdentificationStrategy =
        plantAsset.recipeIdentificationStrategy;
    } else {
      this._loadDefaultColorTask.perform();
    }

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'name',
          message: l10n.t('You must enter a name.'),

          validate: (value) => value,
        },
        {
          propertyName: 'type',
          message: l10n.t('You must select a type.'),

          validate: (value) => value,
        },
      ],
    });
  }

  _loadDefaultColorTask = task(async () => {
    let plantAssets: PlantAsset[] = await this.plantAssetStore.findAll({}, {});

    let count = plantAssets.length;
    let nextColor = chartColors[count];

    if (nextColor) {
      this.formData.displayColor = nextColor;
    }
  });
}

class FormData {
  id?: string;
  @tracked name?: string;
  @tracked type?: PlantAssetType;
  @tracked modelIdentifier?: string;
  @tracked year?: string;
  @tracked referenceNumber?: string;
  @tracked displayColor?: string;
  @tracked position?: string;
  @tracked idPerLocation?: string;
  @tracked recipeIdentificationStrategy?: RecipeIdentificationStrategy;
}
