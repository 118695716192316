import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { action } from '@ember/object';
import { cloneUser } from 'fabscale-app/models/user';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import UserSessionService from 'fabscale-app/services/user-session';
import StoreUserService from 'fabscale-app/services/store/user';
import {
  Locale,
  LocaleLabelPair,
  LocaleLabels,
} from 'fabscale-app/models/enums/locales';
import { SuccessMessage } from 'fabscale-app';
import { sortBy } from 'fabscale-app/utilities/utils/array';
import AnalyticsService from 'fabscale-app/services/analytics';
import ErrorParserService from 'fabscale-app/services/error-parser';

class FormData {
  @tracked name: string;
  @tracked locale: Locale;
  email: string;
  id: string;
}

export default class SettingsMyProfile extends Component {
  @service l10n: L10nService;
  @service userSession: UserSessionService;
  @service('store/user') userStore: StoreUserService;
  @service analytics: AnalyticsService;
  @service('error-parser') errorParser: ErrorParserService;

  // Properties
  formData: FormData;
  formModel: FormDataModel<FormData>;

  availableLocales = sortBy(LocaleLabels, 'label');

  @tracked successMessage?: SuccessMessage;

  get selectedLocale() {
    let { locale } = this.formData;

    return locale
      ? this.availableLocales.find((localeData) => localeData.locale === locale)
      : undefined;
  }

  constructor(owner: unknown, args: any) {
    super(owner, args);

    let { l10n } = this;
    let user = this.userSession.user!;

    this.formData = new FormData();
    this.formData.id = user.id;
    this.formData.name = user.name;
    this.formData.locale = user.locale;
    this.formData.email = user.email;

    this.formModel = new FormDataModel({
      data: this.formData,
      validations: [
        {
          propertyName: 'name',
          message: l10n.t('The full name must not be blank.'),
          validate: (value) => value,
        },
      ],
    });
  }

  @action
  updateName(name: string) {
    this.formModel.updateProperty('name', name);

    this.analytics.addEvent('settings-profile-update-name');
  }

  @action
  updateLocale(localeData: LocaleLabelPair) {
    this.formModel.updateProperty('locale', localeData.locale);

    this.analytics.addEvent('settings-profile-update-locale', [
      { name: 'locale', value: localeData.locale },
    ]);
  }

  @action
  hideSuccessMessage() {
    this.formModel.successMessage = undefined;
  }

  updateUserTask = dropTask(async () => {
    await this.formModel.validate();

    if (this.formModel.isInvalid) {
      return;
    }

    let { l10n, userStore } = this;
    let { name, locale, id } = this.formData;
    let currentUser = this.userSession.user!;

    this.formModel.successMessage = undefined;

    let localeHasChanged = locale !== currentUser.locale;

    try {
      await userStore.update(id, { name, locale });
    } catch (error) {
      this._handleError(error);
      return;
    }

    let newCurrentUser = cloneUser(currentUser, { name, locale });
    this.userSession.user = newCurrentUser;

    if (localeHasChanged) {
      await this.userSession.setLocale(locale);
    }

    this.formModel.hasChanges = false;

    this.formModel.hasSuccess({
      title: l10n.t('User details saved'),
      description: l10n.t('Your profile has been successfully updated.'),
    });
  });

  _handleError(error: any) {
    this.formModel.addError(this.errorParser.getErrorMessage(error));
  }
}
