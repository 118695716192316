import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { tracked } from '@glimmer/tracking';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import { service } from '@ember/service';
import AnalyticsService from 'fabscale-app/services/analytics';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import { dropTask } from 'ember-concurrency';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { use } from 'ember-could-get-used-to-this';
import LoadRecipesInUseResource from 'fabscale-app/helpers/resources/load-recipes-in-use';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import { RelativeTimeframe } from 'fabscale-app/models/enums/relative-timeframe';
import { relativeTimeframeToDateRange } from 'fabscale-app/utilities/utils/serialize-relative-timeframe';
import { KpiDataFilterSettings } from 'fabscale-app/models/kpi-data-filter-settings';
import { KpiDataFilterTemplate } from 'fabscale-app/models/kpi-data-filter-template';
import KpiDataFilterService from 'fabscale-app/services/kpi-data-filter';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import PdfService from 'fabscale-app/services/pdf';
import { DateTime } from 'luxon';
import {
  AlarmTypeReportFilters,
  AlarmTypeReportFormData,
} from 'fabscale-app/models/alarm-type-report';
import {
  PlantAssetAlarmType,
  PlantAssetAlarmTypes,
} from 'fabscale-app/models/enums/plant-asset-alarm-type';
import SettingsService from 'fabscale-app/services/settings';
import UserSessionService from 'fabscale-app/services/user-session';

interface Args {
  filters: AlarmTypeReportFilters;
  updateFilters: (filters: AlarmTypeReportFilters) => void;
  allPlantAssets: PlantAsset[];
  defaultTimeRange: TimeRange;
  canClose: boolean;
}

export default class PageAlarmReportTypeReportFilters extends Component<Args> {
  @service settings: SettingsService;
  @service userSession: UserSessionService;
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @tracked formModel: FormDataModel<AlarmTypeReportFormData>;
  @tracked formData: AlarmTypeReportFormData;
  @tracked label: string;
  @service kpiDataFilter: KpiDataFilterService;
  availableTemplates: KpiDataFilterTemplate[];
  allAlarmTypes = PlantAssetAlarmTypes;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    const {
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds,
      alarmType,
      alarmExternalIds,
      recipeIds,
    } = this.args.filters;

    this.initFormModel({
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
      recipeIds,
    });

    this.availableTemplates = this.kpiDataFilter.getAvailableTemplates();
    this.label =
      this.availableTemplates.length > 0
        ? 'Choose template or Time frame'
        : 'Choose timeframe';
  }

  private initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    timeRange: TimeRangeOptional;
    alarmType?: PlantAssetAlarmType;
    alarmExternalIds?: string[];
    plantAssetIds?: string[];
    recipeIds?: string[];
  }) {
    const { l10n } = this;
    const { dateRange, timeRange, alarmType, alarmExternalIds, plantAssetIds } =
      initialData;

    this.formData = new AlarmTypeReportFormData();

    Object.assign(this.formData, {
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
    });

    this.formModel = new FormDataModel<AlarmTypeReportFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRangeOptional | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },
          message: l10n.t('You have to select a date range.'),
        },
        {
          propertyName: 'alarmType',
          validate(value: PlantAssetAlarmType | undefined) {
            return Boolean(value);
          },
          message: l10n.t('You have to select an alarm type.'),
        },
      ],
    });
  }

  @use
  allRecipesAsyncResource = new LoadRecipesInUseResource(() => [
    { dateRange: this.exactDateRange, pageSize: 999 },
  ]);

  get selectedAlarmExternalIds() {
    const { alarmExternalIds } = this.formData;

    if (!alarmExternalIds) {
      return [];
    }

    return alarmExternalIds;
  }

  get selectedPlantAssets() {
    const { allPlantAssets } = this.args;
    const { plantAssetIds } = this.formData;

    if (!allPlantAssets || !plantAssetIds) {
      return [];
    }

    return plantAssetIds
      .map((id) => allPlantAssets.find((plantAsset) => plantAsset.id === id))
      .filter(Boolean) as PlantAsset[];
  }

  get allRecipes() {
    return getAsyncResourceData(this.allRecipesAsyncResource, []);
  }

  get selectedRecipes() {
    const { allRecipes } = this;
    const { recipeIds } = this.formData;

    if (!allRecipes || !recipeIds) {
      return [];
    }

    return allRecipes.filter((recipe) => recipeIds!.includes(recipe.id));
  }

  get exactDateRange(): DateRange | undefined {
    const { dateRange, timeRange } = this.formData;

    if (!dateRange || !dateRange.start || !dateRange.end) {
      return undefined;
    }

    return getExactDateRangeHelper({
      dateRange: dateRange as DateRange,
      timeRange: timeRange!,
      maxNow: true,
    });
  }

  get activeFilters(): { label: string; value: string }[] {
    const { l10n } = this;

    const dateStart = this.args.filters?.dateRange?.start;
    const dateEnd = this.args.filters?.dateRange?.end;

    const { alarmType, alarmExternalIds } = this.formData;

    return [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Alarm type'),
        value: alarmType!, //TODO: check this
      },
      {
        label: l10n.t('Alarm external IDs'),
        value:
          alarmExternalIds && alarmExternalIds.length > 0
            ? alarmExternalIds.join(', ')
            : l10n.t('All external IDs'),
      },
      {
        label: l10n.t('Roasters'),
        value:
          this.selectedPlantAssets.length > 0
            ? this.selectedPlantAssets
                .map((plantAsset) => plantAsset.name)
                .join(', ')
            : l10n.t('All roasters'),
      },
      {
        label: l10n.t('Recipes'),
        value:
          this.selectedRecipes.length > 0
            ? this.selectedRecipes.map((recipe) => recipe.name).join(', ')
            : l10n.t('All recipes'),
      },
    ];
  }

  get filtersForTemplateSelection() {
    const { dateRange, timeRange, alarmType, plantAssetIds, recipeIds } =
      this.formData;

    return {
      dateRange,
      timeRange,
      alarmType,
      plantAssetIds: plantAssetIds,
      recipeIds,
    };
  }

  @action
  onSelectTemplate(template: KpiDataFilterTemplate) {
    const {
      relativeTimeframe,
      absoluteTimeframe,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
      recipeIds,
    } = template.filterSettings;

    this.formData.relativeTimeframe = relativeTimeframe;
    this.formData.dateRange = relativeTimeframe
      ? relativeTimeframeToDateRange(relativeTimeframe)
      : (absoluteTimeframe as DateRange);

    this.formData.timeRange =
      timeRange && timeRange.start && timeRange.end
        ? (timeRange as TimeRange)
        : this.args.defaultTimeRange;

    this.formData.alarmType = alarmType;
    this.formData.alarmExternalIds = alarmExternalIds || [];
    this.formData.plantAssetIds = plantAssetIds || [];
    this.formData.recipeIds = recipeIds || [];
  }

  @action
  updateDateRange(dateRange: DateRange) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.analytics.addEvent('alarm-type-report-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: getDateRangeLength(dateRange),
      },
    ]);
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('alarm-type-report-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  updateAlarmType(alarmType: PlantAssetAlarmType) {
    this.formModel.updateProperty('alarmType', alarmType);

    this.analytics.addEvent('alarm-type-report-filter-alarm-types');
  }

  @action
  updateAlarmExternalIds(alarmExternalIds?: string[]) {
    this.formModel.updateProperty('alarmExternalIds', alarmExternalIds);

    this.analytics.addEvent('alarm-type-report-filter-alarm-externalIds');
  }

  @action
  updatePlantAssets(plantAssets: PlantAsset[]) {
    const plantAssetIds = plantAssets.map((plantAsset) => plantAsset.id);
    this.formModel.updateProperty('plantAssetIds', plantAssetIds);

    this.analytics.addEvent('alarm-type-report-filter-plant-assets');
  }

  @action
  updateRecipes(recipes: { id: string; name: string }[]) {
    const recipeIds = recipes.map((recipe) => recipe.id);
    this.formModel.updateProperty('recipeIds', recipeIds);

    this.analytics.addEvent('alarm-type-report-recipes', [
      { name: 'selectedCount', value: recipes.length },
    ]);
  }

  @action
  onSelectRelativeTimeframe(relativeTimeframe: RelativeTimeframe) {
    this.formData.relativeTimeframe = relativeTimeframe;
    const { timeRange } = this.formData;

    if (relativeTimeframe) {
      this.formData.dateRange = relativeTimeframeToDateRange(
        relativeTimeframe,
        timeRange
      );
    }
  }

  @action
  clearFilters() {
    this.initFormModel({
      dateRange: undefined,
      timeRange: this.args?.defaultTimeRange,
      alarmType: undefined,
      plantAssetIds: [],
      recipeIds: [],
    });
  }

  @action
  resetFilters() {
    const {
      dateRange,
      timeRange = this.args?.defaultTimeRange,
      alarmType,
      plantAssetIds = [],
      recipeIds = [],
    } = this.args.filters;
    this.initFormModel({
      dateRange,
      timeRange,
      alarmType,
      plantAssetIds,
      recipeIds,
    });
  }

  printPageTask = dropTask(async () => {
    const fileName = `fabscale-alarm-type-report-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    const isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    const {
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
      recipeIds,
      templateName,
    } = this.formData;

    if (templateName) {
      const filterSettings = new KpiDataFilterSettings({
        absoluteTimeframe: dateRange,
        timeRange,
        alarmType,
        alarmExternalIds,
        plantAssetIds,
        recipeIds,
      });

      const kpiDataFilterTemplate = new KpiDataFilterTemplate({
        name: templateName,
        filterSettings,
      });

      await this.kpiDataFilter.addCustomTemplate(kpiDataFilterTemplate);
      this.analytics.addEvent('alarm-type-report-template-add');
    }

    this.args.updateFilters({
      dateRange,
      timeRange,
      alarmType,
      alarmExternalIds,
      plantAssetIds,
      recipeIds,
    });

    return true;
  });
}
