import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import CellActions from 'fabscale-app/components/page/roast-batches/overview/table/cell/actions';
import CellBoolean from 'fabscale-app/components/page/roast-batches/overview/table/cell/boolean';
import CellGoalResults from 'fabscale-app/components/page/roast-batches/overview/table/cell/goal-results';
import CellStatus from 'fabscale-app/components/page/roast-batches/overview/table/cell/status';
import CellId from 'fabscale-app/components/page/roast-batches/overview/table/cell/id';
import CellNumericAmount from 'fabscale-app/components/page/roast-batches/overview/table/cell/numeric-amount';
import {
  BooleanRoastBatchParameterType,
  BooleanRoastBatchParameterTypes,
  NumericRoastBatchParameterType,
  NumericRoastBatchParameterTypes,
  RoastBatchParameterType,
} from 'fabscale-app/models/enums/roast-batch-parameter-type';
import { TableGetColumnInfoHandler } from 'fabscale-app/helpers/data-table/get-column-info';
import { TableGetRowInfoHandler } from 'fabscale-app/helpers/data-table/get-row-info';
import { RoastBatch } from 'fabscale-app/models/roast-batch';
import {
  TableColumnDefinition,
  TableColumnDefinitionInput,
} from 'fabscale-app/models/table-column-definition';
import AnalyticsService from 'fabscale-app/services/analytics';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import ExcelService, { ExcelAmountCell } from 'fabscale-app/services/excel';
import { DateFormat } from 'fabscale-app/utilities/utils/format-date';
import { DateTime } from 'luxon';

interface Args {
  roastBatches: RoastBatch[];
  editRoastBatchId?: string;
  availableRoastBatchParameterTypes: RoastBatchParameterType[];
  selectedRoastBatchParameterTypes: RoastBatchParameterType[];
  shouldAutoRefresh: boolean;
  disableAutoRefresh: () => void;
  enableAutoRefreshIfHasDefaults: () => void;
  toggleEditRoastBatch: (roastBatch: RoastBatch | undefined) => void;
}

type RoastBatchOrEditRow =
  | RoastBatch
  | {
      edit: boolean;
      roastBatch: RoastBatch;
    };

export default class RoastBatchesOverviewTable extends Component<Args> {
  @service l10n: L10nService;
  @service excel: ExcelService;
  @service analytics: AnalyticsService;
  @service enumLabels: EnumLabelsService;

  // We keep a list of roasts we updated here,
  // to avoid needing to re-fetch the whole page (showing a loading spinner etc.)
  @tracked updatedRoastBatches: RoastBatch[] = [];

  @cached
  get rowData(): RoastBatchOrEditRow[] {
    let roastBatches = this.args.roastBatches.slice();
    let { updatedRoastBatches } = this;
    let { editRoastBatchId } = this.args;

    // "Fix" roasts that have just been updated in-place
    updatedRoastBatches.forEach((roastBatch) => {
      let existingPos = roastBatches.findIndex(
        (existing) => existing.id === roastBatch.id
      );

      if (existingPos > -1) {
        roastBatches.splice(existingPos, 1, roastBatch);
      }
    });

    // Add an "edit" row below a roast batch, if editRoastBatchId is set
    if (!editRoastBatchId) {
      return roastBatches;
    }

    let rowData: RoastBatchOrEditRow[] = roastBatches;

    let roastBatch = roastBatches.find(
      (roastBatch) => roastBatch.id === editRoastBatchId
    );

    if (roastBatch) {
      let pos = rowData.indexOf(roastBatch);

      let editRow = {
        edit: true,
        roastBatch,
      };

      rowData.splice(pos + 1, 0, editRow);
    }

    return rowData;
  }

  get selectedNumericPropertyTypes(): NumericRoastBatchParameterType[] {
    return this.args.selectedRoastBatchParameterTypes.filter(
      filterNumericParameterType
    );
  }

  get selectedBooleanPropertyTypes(): BooleanRoastBatchParameterType[] {
    return this.args.selectedRoastBatchParameterTypes.filter(
      filterBooleanParameterType
    );
  }

  @cached
  get columns(): TableColumnDefinitionInput[] {
    let {
      l10n,
      selectedNumericPropertyTypes,
      selectedBooleanPropertyTypes,
      enumLabels,
    } = this;

    let cols: TableColumnDefinitionInput[] = [
      {
        propertyName: 'id',
        title: l10n.t('Roast-ID'),
        component: CellId,
        thClass: 'nowrap',
        tdClass: 'nowrap',
        noCompactTitle: true,
        sortBy: 'ID',
      },
      {
        propertyName: 'sourceSystemRecordingDate',
        title: l10n.t('Date'),
        cellType: 'DATE',
        sortBy: 'SOURCE_SYSTEM_RECORDING_DATE',
        cellData: {
          dateFormat: DateFormat.DateTime,
        },
      },
      {
        propertyName: 'numericRoastBatchParameters.goalResults',
        title: l10n.t('Goals'),
        component: CellGoalResults,
        disableSorting: true,
      },
      {
        propertyName: 'plantAsset.name',
        title: l10n.t('Roaster'),
        sortBy: 'PLANT_ASSET_NAME',
      },
      {
        propertyName: 'recipe.name',
        title: l10n.t('Recipe'),
        sortBy: 'RECIPE_NAME',
      },
      {
        propertyName: 'status',
        title: l10n.t('Status'),
        disableSorting: true,
        component: CellStatus,
      },
    ];

    selectedNumericPropertyTypes.forEach((type) => {
      cols.push({
        propertyName: type,
        title: enumLabels.roastBatchParameterType(type),
        component: CellNumericAmount,
        disableSorting: true,
        tdClass: 'text-right nowrap',
        thClass: 'text-right',
      });
    });

    selectedBooleanPropertyTypes.forEach((type) => {
      cols.push({
        propertyName: type,
        title: enumLabels.roastBatchParameterType(type),
        component: CellBoolean,
        disableSorting: true,
        tdClass: 'text-right nowrap',
        thClass: 'text-right',
      });
    });

    cols.push({
      propertyName: '',
      title: '',
      component: CellActions,
    });

    return cols;
  }

  @cached
  get getRowInfoHandler(): TableGetRowInfoHandler | undefined {
    let { rowData } = this;
    let { editRoastBatchId } = this.args;

    if (editRoastBatchId) {
      return (record: RoastBatchOrEditRow) => {
        let index = rowData.indexOf(record);

        let classes = ['transition-common'];

        if (index % 2) {
          classes.push('table__tr--striped');
        }

        if (!('edit' in record) && record.id !== editRoastBatchId) {
          classes.push('opacity-30');
        } else {
          classes.push('table__tr--striped');
        }

        return { class: classes.join(' ') };
      };
    }

    return (record: RoastBatchOrEditRow) => {
      let index = rowData.indexOf(record);

      let classes = ['transition-common'];

      if (index % 2) {
        classes.push('table__tr--striped');
      }

      return { class: classes.join(' ') };
    };
  }

  @cached
  get getColumnInfoHandler(): TableGetColumnInfoHandler | undefined {
    let { editRoastBatchId } = this.args;

    if (!editRoastBatchId) {
      return undefined;
    }

    let columnCount = this.columns.length;

    return (column: TableColumnDefinition, record: RoastBatchOrEditRow) => {
      if (!('edit' in record)) {
        return { colspan: undefined, showColumn: true };
      }

      // Editing this record - only show action column
      return column.propertyName === ''
        ? { colspan: columnCount, showColumn: true }
        : { showColumn: false };
    };
  }

  @action
  onEditRowSuccess(updatedRoastBatch: RoastBatch) {
    let updatedRoastBatches = this.updatedRoastBatches.slice();
    updatedRoastBatches.push(updatedRoastBatch);
    this.updatedRoastBatches = updatedRoastBatches;

    this.args.toggleEditRoastBatch(undefined);
  }

  @action
  resetEdit() {
    this.args.toggleEditRoastBatch(undefined);

    this.updatedRoastBatches = [];
  }

  @action
  resetEditIfAutoRefresh() {
    if (this.args.shouldAutoRefresh) {
      this.args.toggleEditRoastBatch(undefined);
    }
  }

  downloadExcelTask = dropTask(async () => {
    let { roastBatches, availableRoastBatchParameterTypes } = this.args;
    let { excel, l10n, enumLabels } = this;

    let rows = roastBatches.map((roastBatch) => {
      let { id, sourceSystemRecordingDate, recipe, plantAsset } = roastBatch;

      let row: {
        roastId: string;
        sourceSystemRecordingDate: DateTime;
        plantAssetName: string;
        recipeName: string;
      } & {
        [key in RoastBatchParameterType]?: ExcelAmountCell | boolean;
      } = {
        roastId: id,
        sourceSystemRecordingDate,
        plantAssetName: plantAsset.name,
        recipeName: recipe.name,
      };

      availableRoastBatchParameterTypes.forEach((type) => {
        if (filterBooleanParameterType(type)) {
          let parameter = roastBatch.booleanRoastBatchParameters.find(
            (parameter) => parameter.parameterType === type
          );

          row[type] = parameter?.value;
        }

        if (filterNumericParameterType(type)) {
          let parameter = roastBatch.numericRoastBatchParameters.find(
            (parameter) => parameter.parameterType === type
          );

          if (parameter) {
            row[type] = new ExcelAmountCell({
              amount: parameter.value,
              unit: parameter.unit,
            });
          }
        }
      });

      return row;
    });

    let columns = [
      { header: l10n.t('Roast-ID'), id: 'roastId' },
      { header: l10n.t('Date'), id: 'sourceSystemRecordingDate' },
      { header: l10n.t('Roaster'), id: 'plantAssetName' },
      { header: l10n.t('Recipe'), id: 'recipeName' },
    ];

    availableRoastBatchParameterTypes.forEach((parameter) => {
      columns.push({
        header: enumLabels.roastBatchParameterType(parameter),
        id: parameter,
      });
    });

    let sheetConfig = {
      sheetName: l10n.t('Roast batches'),
      autoFilter: true,
      columns,
      rows,
    };

    this.analytics.addEvent('roast-batches-download-excel');

    await excel.create(
      sheetConfig,
      `fabscale-roast-batches-${DateTime.local().toISODate()}.xlsx`
    );
  });
}

function filterNumericParameterType(
  type: RoastBatchParameterType
): type is NumericRoastBatchParameterType {
  // @ts-ignore
  return NumericRoastBatchParameterTypes.includes(type);
}

function filterBooleanParameterType(
  type: RoastBatchParameterType
): type is BooleanRoastBatchParameterType {
  // @ts-ignore
  return BooleanRoastBatchParameterTypes.includes(type);
}
