import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { use } from 'ember-could-get-used-to-this';
import { isNotOeeKpi, KpiType } from 'fabscale-app/models/enums/kpi-types';
import getExactDateRangeHelper from 'fabscale-app/helpers/get-exact-date-range';
import LoadAvailableKpiTypesResource from 'fabscale-app/helpers/resources/load-available-kpi-types';
import LoadPlantAssetsInUseResource from 'fabscale-app/helpers/resources/load-plant-assets-in-use';
import LoadRecipesInUseResource from 'fabscale-app/helpers/resources/load-recipes-in-use';
import { DateRange, DateRangeOptional } from 'fabscale-app/models/date-range';
import { FormDataModel } from 'fabscale-app/models/form-data';
import { PlantAsset } from 'fabscale-app/models/plant-asset';
import { TimeRange, TimeRangeOptional } from 'fabscale-app/models/time-range';
import AnalyticsService from 'fabscale-app/services/analytics';
import AvailableDataService from 'fabscale-app/services/available-data';
import EnumLabelsService from 'fabscale-app/services/enum-labels';
import PdfService from 'fabscale-app/services/pdf';
import { getDateRangeLength } from 'fabscale-app/utilities/utils/date-range';
import {
  DateFormat,
  formatDate,
} from 'fabscale-app/utilities/utils/format-date';
import { getAsyncResourceData } from 'fabscale-app/utilities/utils/get-async-resource-value';
import { DateTime } from 'luxon';
import { KpiDetailsReportFilters } from '.';

interface Args {
  filters: KpiDetailsReportFilters;
  updateFilters: (filters: KpiDetailsReportFilters) => void;

  defaultTimeRange: TimeRange;
  canClose: boolean;
}

class KpiDetailsReportFilterFormData {
  @tracked dateRange?: DateRangeOptional;
  @tracked timeRange: TimeRange;
  @tracked recipeIds: string[];
  @tracked plantAssetIds: string[];
  @tracked kpiType?: KpiType;
}

export default class KpiDetailsReportFiltersComponent extends Component<Args> {
  @service pdf: PdfService;
  @service analytics: AnalyticsService;
  @service l10n: L10nService;
  @service availableData: AvailableDataService;
  @service enumLabels: EnumLabelsService;

  @tracked formModel: FormDataModel<KpiDetailsReportFilterFormData>;
  @tracked formData: KpiDetailsReportFilterFormData;

  @cached
  get exactDateRange(): DateRange | undefined {
    let { dateRange, timeRange } = this.formData;

    if (!dateRange || !dateRange.start || !dateRange.end) {
      return undefined;
    }

    return getExactDateRangeHelper({
      dateRange: dateRange as DateRange,
      timeRange,
      maxNow: true,
    });
  }

  @use
  availableKpiTypesAsyncResource = new LoadAvailableKpiTypesResource(() => [
    {},
  ]);

  get availableKpiTypes() {
    return getAsyncResourceData(this.availableKpiTypesAsyncResource, []);
  }

  get oeeRestrictedAvailableKpiTypes() {
    return getAsyncResourceData(
      this.availableKpiTypesAsyncResource,
      []
    )?.filter((kpiType) => isNotOeeKpi(kpiType));
  }

  @use
  allPlantAssetsAsyncResource = new LoadPlantAssetsInUseResource(() => [
    { dateRange: this.exactDateRange },
  ]);

  get allPlantAssets() {
    return getAsyncResourceData(this.allPlantAssetsAsyncResource, []);
  }

  @use
  allRecipesAsyncResource = new LoadRecipesInUseResource(() => [
    { dateRange: this.exactDateRange, pageSize: 999 },
  ]);

  get allRecipes() {
    return getAsyncResourceData(this.allRecipesAsyncResource, []);
  }

  get selectedPlantAssets() {
    let { allPlantAssets } = this;
    let { plantAssetIds } = this.formData;

    if (!allPlantAssets || !plantAssetIds) {
      return [];
    }

    return allPlantAssets.filter((plantAsset) =>
      plantAssetIds!.includes(plantAsset.id)
    );
  }

  get selectedRecipes() {
    let { allRecipes } = this;
    let { recipeIds } = this.formData;

    if (!allRecipes || !recipeIds) {
      return [];
    }

    return allRecipes.filter((recipe) => recipeIds!.includes(recipe.id));
  }

  get activeFilters(): { label: string; value: string }[] {
    let { l10n, enumLabels } = this;

    let dateStart = this.args.filters.dateRange?.start;
    let dateEnd = this.args.filters.dateRange?.end;
    let { kpiType } = this.formData;

    let filters = [
      {
        label: l10n.t('Date from'),
        value: dateStart ? formatDate(dateStart, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('Date to'),
        value: dateEnd ? formatDate(dateEnd, DateFormat.Date) : '-',
      },
      {
        label: l10n.t('KPI'),
        value: kpiType ? enumLabels.kpiType(kpiType) : '-',
      },
      {
        label: l10n.t('Roasters'),
        value:
          this.selectedPlantAssets.length > 0
            ? this.selectedPlantAssets
                .map((plantAsset) => plantAsset.name)
                .join(', ')
            : l10n.t('All roasters'),
      },
      {
        label: l10n.t('Recipes'),
        value:
          this.selectedRecipes.length > 0
            ? this.selectedRecipes.map((recipe) => recipe.name).join(', ')
            : l10n.t('All recipes'),
      },
    ];

    return filters;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let {
      kpiType,
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      recipeIds = [],
    } = this.args.filters;
    this._initFormModel({
      kpiType,
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
    });
  }

  @action
  updateDateRange(dateRange: DateRangeOptional) {
    if (dateRange.start && dateRange.end) {
      this.formModel.updateProperty('dateRange', dateRange);
    } else {
      this.formData.dateRange = dateRange;
    }

    this.analytics.addEvent('kpi-details-filter-date-range', [
      {
        name: 'dateRangeLength',
        value: getDateRangeLength(dateRange),
      },
    ]);
  }

  @action
  updateTimeRange(timeRange: TimeRange) {
    this.formModel.updateProperty('timeRange', timeRange);

    this.analytics.addEvent('kpi-details-filter-time-range', [
      {
        name: 'start',
        value: timeRange.start,
      },
      {
        name: 'end',
        value: timeRange.end,
      },
    ]);
  }

  @action
  updateKpiType(kpiType: KpiType) {
    this.formModel.updateProperty('kpiType', kpiType);

    this.analytics.addEvent('kpi-details-filter-kpi-type', [
      { name: 'kpiType', value: kpiType },
    ]);
  }

  @action
  updatePlantAssets(plantAssets: PlantAsset[]) {
    let plantAssetIds = plantAssets.map((plantAsset) => plantAsset.id);
    this.formModel.updateProperty('plantAssetIds', plantAssetIds);

    this.analytics.addEvent('kpi-details-filter-plant-assets', [
      { name: 'selectedCount', value: plantAssets.length },
    ]);
  }

  @action
  updateRecipes(recipes: { id: string; name: string }[]) {
    let recipeIds = recipes.map((recipe) => recipe.id);
    this.formModel.updateProperty('recipeIds', recipeIds);

    this.analytics.addEvent('kpi-details-filter-recipes', [
      { name: 'selectedCount', value: recipes.length },
    ]);
  }

  printPageTask = dropTask(async () => {
    let fileName = `fabscale-kpi-details-${
      this.args.filters.kpiType
    }-${DateTime.local().toISODate()}.pdf`;

    await this.pdf.generateForCurrentPageTask.perform(fileName);
  });

  applyFiltersTask = dropTask(async () => {
    let isValid: boolean = await this.formModel.validate();

    if (!isValid) {
      return false;
    }

    let { kpiType, dateRange, timeRange, plantAssetIds, recipeIds } =
      this.formData;

    let actualTimeRange =
      JSON.stringify(timeRange) === JSON.stringify(this.args.defaultTimeRange)
        ? undefined
        : timeRange;

    this.args.updateFilters({
      kpiType,
      dateRange,
      timeRange: actualTimeRange,
      plantAssetIds,
      recipeIds,
    });

    return true;
  });

  @action
  clearFilters() {
    this._initFormModel({
      dateRange: undefined,
      timeRange: this.args.defaultTimeRange,
      kpiType: undefined,
      plantAssetIds: [],
      recipeIds: [],
    });
  }

  @action
  resetFilters() {
    let {
      kpiType,
      dateRange,
      timeRange = this.args.defaultTimeRange,
      plantAssetIds = [],
      recipeIds = [],
    } = this.args.filters;

    this._initFormModel({
      kpiType,
      dateRange,
      timeRange,
      plantAssetIds,
      recipeIds,
    });
  }

  _initFormModel(initialData: {
    dateRange?: DateRangeOptional;
    timeRange: TimeRangeOptional;
    plantAssetIds: string[];
    recipeIds: string[];
    kpiType?: KpiType;
  }) {
    let { l10n } = this;
    let { kpiType, dateRange, timeRange, recipeIds, plantAssetIds } =
      initialData;

    this.formData = new KpiDetailsReportFilterFormData();

    Object.assign(this.formData, {
      kpiType,
      dateRange,
      timeRange,
      recipeIds,
      plantAssetIds,
    });

    this.formModel = new FormDataModel<KpiDetailsReportFilterFormData>({
      data: this.formData,
      validations: [
        {
          propertyName: 'dateRange',
          validate(value: DateRangeOptional | undefined) {
            return Boolean(value?.start) && Boolean(value?.end);
          },

          message: l10n.t('You have to select a date range.'),
        },
        {
          propertyName: 'kpiType',
          validate(value: KpiType) {
            return Boolean(value);
          },

          message: l10n.t('You have to select a KPI.'),
        },
      ],
    });
  }
}
