import { getOwner } from '@ember/application';
import ApplicationInstance from '@ember/application/instance';
import { assert } from '@ember/debug';
import RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { isTesting, macroCondition } from '@embroider/macros';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/ember';
import { UnauthenticatedError } from 'fabscale-app/models/errors/unauthenticated-error';
import { Company } from 'fabscale-app/models/company';
import { Location } from 'fabscale-app/models/location';
import { User } from 'fabscale-app/models/user';
import {
  setSimulationUser,
  setUser,
} from 'fabscale-app/utilities/utils/local-storage';
import { generateMockUser } from 'fabscale-app/utils/mock-graphql/-private/generate-mock-user';
import CognitoService from './cognito';
import StoreUserService from './store/user';
import UserSessionService from './user-session';
import config from 'fabscale-app/config/environment';
import L10nService from '@ember-gettext/ember-l10n/services/l10n';
import { Locale } from 'fabscale-app/models/enums/locales';

const LOCAL_STORAGE_USER_NAMESPACE = 'fabscale-app-current-user';

export default class UserAuthenticationService extends Service {
  @service router: RouterService;
  @service cognito: CognitoService;
  @service userSession: UserSessionService;
  @service('store/user') userStore: StoreUserService;
  @service l10n: L10nService;

  @tracked loginRequiredErrorMessage?: string;
  @tracked afterLoginMessage?: string;

  get isAuthenticated() {
    return this.cognito.isAuthenticated && Boolean(this.userSession.user);
  }

  private afterLoginUrl?: string;
  private currentUserData?: CurrentUserData;
  private readonly simulationLoginEndpoint = config.simulationLoginEndpoint;

  async login({ username, password }: { username: string; password: string }) {
    await this.cognito.authenticate({ username, password });
    await this.loginSuccessful(username);
  }

  async loginSimulation(email: string) {
    await fetch(this.simulationLoginEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    });

    const browserLanguage = window.navigator.language;
    const filteredLocale = browserLanguage.startsWith('zh') ? 'zh-cn' : 'en';
    const locale = filteredLocale as Locale;

    const owner = getOwner(this) as ApplicationInstance;
    generateMockUser(owner, { email, locale });
    setSimulationUser(email);

    this.router.transitionTo('routes/login-successful');
  }

  async loginSuccessful(email: string) {
    await this.saveCurrentUserData({ email });

    // To ensure that if a user wants to setup MFA right after signing in, they do not need to enter the password again
    this.cognito.hasAuthenticatedForMfaSetup = true;

    this.loginRequiredErrorMessage = undefined;
    this.router.transitionTo('routes/login-successful');
  }

  async logout(loginRequiredErrorMessage?: string) {
    let { router } = this;

    this.loginRequiredErrorMessage = loginRequiredErrorMessage;

    this.resetCurrentUserData();
    this.userSession.user = undefined;
    this.userSession.company = undefined;
    this.userSession.locations = [];
    this.userSession.currentLocation = undefined;

    Sentry.setUser(null);

    await this.cognito.logout();
    await router.transitionTo('routes/login');
  }

  async loadUserData(): Promise<{
    user: User;
    company: Company;
    locations: Location[];
  }> {
    let { userStore } = this;

    let currentUserData = this.cognito.isAuthenticated
      ? this.getCurrentUserData()
      : undefined;

    if (!currentUserData) {
      if (this.cognito.isAuthenticated) {
        this.cognito.logout();
      }

      throw new UnauthenticatedError();
    }

    this.currentUserData = currentUserData;

    return userStore.findCurrent(
      currentUserData.email,
      currentUserData.locale!
    );
  }

  cacheUrlAfterLogin(url?: string) {
    if (
      url &&
      [
        'routes/login-successful',
        'routes/select-location',
        'routes/login',
      ].includes(url)
    ) {
      url = undefined;
    }

    this.afterLoginUrl = url;
  }

  redirectAfterLogin() {
    const routeName = this.afterLoginUrl || 'routes/index';

    this.router.transitionTo(routeName);
  }

  saveCurrentUserData({
    email,
    locationId,
    locale,
  }: {
    email: string;
    locationId?: string;
    locale?: Locale;
  }) {
    let currentUserData = new CurrentUserData({
      email,
      locationId,
      locale,
    });

    this.currentUserData = currentUserData;
    currentUserData.save();
  }

  getCurrentUserData(): CurrentUserData | undefined {
    if (this.currentUserData) {
      return this.currentUserData;
    }

    // Sign in based on cognito for tests - for simplification
    if (macroCondition(isTesting())) {
      let email = this.cognito.cognitoData?.userAttributes?.['email'];

      if (email) {
        this.currentUserData = new CurrentUserData({ email });
        return this.currentUserData;
      }

      return undefined;
    }

    let currentUserData = CurrentUserData.load();
    this.currentUserData = currentUserData;
    return currentUserData;
  }

  getCurrentLocationId() {
    return this.currentUserData?.locationId;
  }

  setCurrentLocationId(locationId: string) {
    assert(
      'setCurrentLocationId cannot be set if no currentUserData is setup',
      Boolean(this.currentUserData)
    );

    this.currentUserData!.locationId = locationId;
    this.currentUserData!.save();
  }

  resetCurrentUserData() {
    this.currentUserData?.destroy();
    this.currentUserData = undefined;
  }
}

class CurrentUserData {
  email: string;
  locationId?: string;
  locale?: Locale;

  constructor({ email, locationId, locale }: SerializedCurrentUserData) {
    this.email = email;
    this.locationId = locationId;
    this.locale = locale;
  }

  static load() {
    if (macroCondition(isTesting())) {
      return undefined;
    }

    try {
      let str = window.localStorage.getItem(LOCAL_STORAGE_USER_NAMESPACE);
      let data: SerializedCurrentUserData | undefined = str
        ? JSON.parse(str)
        : undefined;

      if (data) {
        return new CurrentUserData(data);
      }

      return data;
    } catch (error) {
      // ignore errors here...
    }

    return undefined;
  }

  save() {
    if (macroCondition(isTesting())) {
      return;
    }

    const { email, locationId, locale } = this;
    try {
      setUser({
        email,
        locationId,
        locale,
      });
    } catch (error) {
      // ignore errors here...
    }
  }

  destroy() {
    try {
      window.localStorage.removeItem(LOCAL_STORAGE_USER_NAMESPACE);
    } catch (error) {
      // ignore errors here...
    }
  }
}

interface SerializedCurrentUserData {
  email: string;
  locationId?: string;
  locale?: Locale;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'user-authentication': UserAuthenticationService;
  }
}
