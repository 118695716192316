import { action } from '@ember/object';
import { assert } from '@ember/debug';
import Component from '@glimmer/component';
import Chart, { ChartOptions } from 'chart.js/auto';
import { cached, tracked } from '@glimmer/tracking';
import * as _ from 'lodash-es';
import { service } from '@ember/service';
import StorePlantAssetMonitoringService from 'fabscale-app/services/store/plant-asset-monitoring';
import NavSidebar from 'fabscale-app/nav-sidebar/service';
import {
  ContinuousDataItem,
  ExtendedContinuousData,
  ExtendedContinuousDataItem,
  PlantAssetMonitoringFilters,
} from 'fabscale-app/models/plant-asset-monitoring';
import { getEndTimeIsoString } from 'fabscale-app/utilities/utils/parse-time';
import SettingsService from 'fabscale-app/services/settings';
import { getExactDateRangeWithTimeRange } from 'fabscale-app/utilities/utils/date-range';
import { ContinuousDataType } from 'fabscale-app/models/enums/plant-asset-monitoring';
import { Unit, UnitsEnums } from 'fabscale-app/models/enums/units';
import { PARSING_OPTIONS, cssObj } from 'fabscale-app/utilities/utils/chart';

interface Args {
  filters: PlantAssetMonitoringFilters;
  updateFilters: (filters: PlantAssetMonitoringFilters) => void;
}

export default class PagePlantAssetMonitoring extends Component<Args> {
  @tracked currentActiveToggle: number | undefined = undefined;
  @tracked chartData: ContinuousDataItem[] = [];
  @tracked continuosDataGroupedByUnit: {
    [key: string]: ExtendedContinuousDataItem[];
  };

  @tracked units: Unit[];

  @tracked isNavSidebarOpen = false;
  @tracked legendItems: { label: string; color: string; isActive: boolean }[] =
    [];

  @service('store/plant-asset-monitoring')
  plantAssetMonitoringStore: StorePlantAssetMonitoringService;

  @service('nav-sidebar') navSidebarService: NavSidebar;

  @service settings: SettingsService;

  tooltipId = 'plantAssetMonitoringChartTopltip';
  isChartInit = false;

  private chart: any;

  sensorTypeYAxisPair: {
    sensorType: ContinuousDataType;
    yAxisID: string;
  }[] = [];

  sensorUnitYAxisPair: {
    unit: Unit;
    yAxisID: string;
  }[] = [];

  private getDatasets() {
    let datasets: any = [];

    const sensorColors = this.plantAssetMonitoringStore.getSensorColors();

    this.sensorUnitYAxisPair = [];

    for (let i = 0; i < this.units.length; i++) {
      this.continuosDataGroupedByUnit[this.units[i]!]!.forEach((group) => {
        this.sensorUnitYAxisPair.push({
          unit: group.unit,
          yAxisID: `y-axis-${i % 4}`,
        });
        datasets.push({
          data: group.values,
          borderColor: sensorColors[group.sensorName],
          parsing: PARSING_OPTIONS,
          yAxisID: `y-axis-${i % 4}`,
        });
      });
    }

    this.sensorUnitYAxisPair = _.uniqBy(
      this.sensorUnitYAxisPair,
      (pair: { unit: Unit; yAxisID: string }) => pair.unit
    );

    return datasets;
  }

  @action
  getUnitByIndex(index: number) {
    const pair: {
      unit: Unit;
      yAxisID: string;
    } = this.sensorUnitYAxisPair.find(
      (pair) => pair.yAxisID === `y-axis-${index}`
    )!;

    if (this.chartData) {
      const dataItem: ContinuousDataItem | undefined = this.chartData.find(
        (dataItem) => dataItem.unit === pair.unit
      );

      if (dataItem) {
        const unit = new UnitsEnums().getLabel(dataItem.unit);

        return unit === '' ? '#' : `[${unit}]`;
      }
    }

    return '';
  }

  @action
  getColorsByIndex(index: number) {
    const pair: {
      unit: Unit;
      yAxisID: string;
    } = this.sensorUnitYAxisPair.find(
      (pair) => pair.yAxisID === `y-axis-${index}`
    )!;

    if (pair) {
      const sensors: ExtendedContinuousDataItem[] =
        this.continuosDataGroupedByUnit[pair.unit]!;
      return sensors.map((sensor: any) => sensor.sensorColor);
    }

    return [];
  }

  private getScales(noOfYScalesToBeGenerated: number) {
    let scales: any = {};

    scales.x = {
      offset: true,
      border: {
        display: true,
      },
      grid: {
        display: true,
        drawTicks: true,
        color: 'transparent',
        tickColor: cssObj.colors.lightGray,
        tickLength: 10,
      },
      ticks: {
        display: true,
        callback: function (value: any): any {
          return new Date(
            (this as any).getLabelForValue(value)
          ).toLocaleTimeString('en-US', {
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
          });
        },
      },
    };

    scales['y-axis-0'] = {
      id: 'y-axis-0',
      offset: true,
      position: cssObj.position.left,
      ticks: {
        display: function () {
          return noOfYScalesToBeGenerated > 0 && window.innerWidth > 600;
        },
        padding: 8,
      },
      border: {
        display: false,
        dash: [10, 10],
        dashOffset: 2.0,
      },
      grid: {
        tickColor: cssObj.colors.cultured,
        color: cssObj.colors.lightGray,
      },
      title: {
        display: function () {
          return noOfYScalesToBeGenerated > 0 && window.innerWidth > 600;
        },
        padding: {
          top: 24,
          bottom: 20,
        },
      },
    };

    if (noOfYScalesToBeGenerated > 1) {
      scales['y-axis-1'] = {
        offset: true,
        display: function () {
          return window.innerWidth > 600;
        },
        type: 'linear',
        position: cssObj.position.left,
        stack: 'demo',
        stackWeight: 1,
        border: {
          color: cssObj.colors.lightGray,
        },
        grid: {
          display: true,
          color: cssObj.colors.transparent,
          tickColor: cssObj.colors.lightGray,
          tickLength: 10,
        },
        ticks: {
          display: true,
          padding: 8,
        },
        title: {
          display: function () {
            return window.innerWidth > 600;
          },
          padding: {
            bottom: 20,
          },
        },
      };
    }

    if (noOfYScalesToBeGenerated > 2) {
      scales['y-axis-2'] = {
        display: function () {
          return window.innerWidth > 600;
        },
        offset: true,
        type: 'linear',
        position: cssObj.position.right,
        stackWeight: 1,
        border: {
          display: false,
          color: cssObj.colors.lightGray,
        },
        grid: {
          display: false,
        },
        title: {
          display: function () {
            return window.innerWidth > 600;
          },
          padding: {
            top: 24,
            bottom: 20,
          },
        },
      };
    }

    if (noOfYScalesToBeGenerated > 3) {
      scales['y-axis-3'] = {
        display: function () {
          return window.innerWidth > 600;
        },
        type: 'linear',
        position: cssObj.position.right,
        border: {
          color: cssObj.colors.lightGray,
        },
        grid: {
          display: true,
          color: cssObj.colors.transparent,
          tickColor: cssObj.colors.lightGray,
          tickLength: 10,
        },
        ticks: {
          display: true,
          padding: 8,
        },
        title: {
          display: function () {
            return window.innerWidth > 600;
          },
          padding: {
            top: 24,
            bottom: 20,
          },
        },
      };
    }

    return scales;
  }

  private groupContinuousDataBySensorType() {
    let chartDataCopy: ExtendedContinuousDataItem[] = this.chartData;

    chartDataCopy.forEach((item: ExtendedContinuousDataItem) => {
      item.values.forEach((value: ExtendedContinuousData) => {
        value.sensorName = item.sensorName;
        value.sensorType = item.sensorType;
        value.unit = item.unit;
      });
    });

    this.continuosDataGroupedByUnit = _.groupBy(chartDataCopy, 'unit');

    this.units = Object.keys(this.continuosDataGroupedByUnit) as Unit[];

    const sensorColors = this.plantAssetMonitoringStore.getSensorColors();

    this.units.forEach((unit: Unit) => {
      this.continuosDataGroupedByUnit[unit]!.forEach(
        (dataPerUnit: ExtendedContinuousDataItem) => {
          dataPerUnit.sensorColor = sensorColors[dataPerUnit.sensorName!];
        }
      );
    });
  }

  private computeLegendItems(chart: Chart) {
    this.legendItems = chart.config.data.datasets.map((dataset: any) => {
      return {
        label: dataset.data[0].sensorName,
        color: dataset.borderColor,
        isActive: true,
      };
    });
  }

  @action
  async onInit() {
    if (this.hasAllRequiredData) {
      await this.loadData(this.args.filters);
    }
  }

  private async loadData(filters: PlantAssetMonitoringFilters) {
    const {
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    } = filters;
    const data = await this.plantAssetMonitoringStore.findAll(
      getExactDateRangeWithTimeRange(dateRange!, timeRange),
      plantAssetId!,
      sensorCategories?.length
        ? sensorCategories
        : Object.values(ContinuousDataType),
      sensorNames
    );
    this.chartData = data.items;

    this.groupContinuousDataBySensorType();

    if (!this.isChartInit) {
      this.initChart();
    } else {
      this.updateChartDatasets();
    }
    this.computeLegendItems(this.chart);
  }

  getOrCreateTooltip = (chart: any) => {
    let tooltipEl = document.getElementById(this.tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = cssObj.colors.white;
      tooltipEl.style.borderRadius = cssObj.spacings._3px;
      tooltipEl.style.borderWidth = cssObj.spacings._1px;
      tooltipEl.style.border = `${cssObj.spacings._1px} ${cssObj.borders.solid} ${cssObj.colors.lightGray}`;
      tooltipEl.style.borderColor = cssObj.colors.lightGray;

      tooltipEl.style.opacity = cssObj.opacity._1;
      tooltipEl.style.pointerEvents = cssObj.pointerEvents.none;
      tooltipEl.style.position = cssObj.position.absolute;
      tooltipEl.style.transform = cssObj.transform.translate1;
      tooltipEl.style.transition = cssObj.transition.allEase1;

      const table = document.createElement('table');
      table.style.margin = cssObj.spacings._0px;

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  externalTooltipHandler = (context: any) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = this.getOrCreateTooltip(chart);

    if (!tooltip?.dataPoints) {
      return;
    }

    const label = chart.scales.x.ticks[tooltip.dataPoints[0].dataIndex]?.label;

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = cssObj.opacity._0;
      return;
    }

    tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
    tooltipEl.id = this.tooltipId;

    tooltipEl.classList.add(tooltip.yAlign);
    tooltipEl.classList.add(tooltip.xAlign);

    // Set Text
    if (tooltip.body) {
      const bodyLines = tooltip.body.map((b: any) => b.lines);

      const tableHead = document.createElement('thead');

      const tableBody = document.createElement('tbody');
      tableBody.style.whiteSpace = cssObj.whiteSpace.nowrap;

      bodyLines.forEach((body: any, i: any) => {
        const colors = tooltip.labelColors[i];
        const unit = new UnitsEnums().getLabel(
          tooltip.dataPoints[i].dataset.data[0].unit
        );
        const sensorName = tooltip.dataPoints[i].dataset.data[0].sensorName;

        const span = document.createElement('span');
        span.style.background = colors.borderColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = cssObj.spacings._0px;
        span.style.marginRight = cssObj.spacings._10px;
        span.style.height = cssObj.spacings._12px;
        span.style.width = cssObj.spacings._12px;
        span.style.display = cssObj.display.inlineBlock;

        const tr = document.createElement('tr');
        tr.style.backgroundColor = cssObj.colors.inherit;
        const td = document.createElement('td');

        let span1 = document.createElement('span');
        span1.style.display = cssObj.display.flex;
        span1.style.flexDirection = cssObj.flex.flexDirection.row;

        let p1 = document.createElement('p');
        p1.innerHTML = `${sensorName}:`;
        p1.style.marginRight = cssObj.spacings._8px;

        let p2 = document.createElement('p');
        p2.innerHTML = body;

        let p3 = document.createElement('p');
        p3.innerHTML = unit === '' ? '#' : unit;

        p1.style.marginBottom =
          p2.style.marginBottom =
          p3.style.marginBottom =
            cssObj.spacings._0px;

        p2.style.fontWeight = p3.style.fontWeight = cssObj.fontWeight._700;

        span1.append(p1, p2, p3);

        let tableDataContainer = document.createElement('div');
        tableDataContainer.style.display = 'flex';
        tableDataContainer.style.flexDirection = 'row';
        tableDataContainer.style.alignItems = 'center';

        tableDataContainer.append(span, span1);

        td.appendChild(tableDataContainer);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      let p4 = document.createElement('p');
      p4.style.marginBottom = cssObj.spacings._0px;
      p4.style.color = cssObj.colors.sonicSilver;
      p4.style.fontSize = cssObj.spacings._12px;
      p4.innerHTML = label;

      tableBody.appendChild(p4);

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot?.firstChild) {
        tableRoot?.firstChild.remove();
      }

      // Add new children
      tableRoot?.appendChild(tableHead);
      tableRoot?.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = cssObj.opacity._1;
    tooltipEl.style.left = `${positionX}${tooltip.caretX}px`;
    tooltipEl.style.top = `${positionY}${tooltip.caretY}px`;
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = cssObj.spacings._8px;
  };

  navSidebarToggled() {
    this.isNavSidebarOpen = this.navSidebarService.isNavSidebarOpen();
  }

  @action
  initChart() {
    this.isNavSidebarOpen = this.navSidebarService.isNavSidebarOpen();

    // eslint-disable-next-line ember/no-observers
    this.navSidebarService.addObserver('isOpen', this, 'navSidebarToggled');

    const chartId = 'plant-asset-monitoring';
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>(
      document.getElementById(chartId)
    );

    const options: ChartOptions = {
      interaction: {
        intersect: false,
        mode: 'index',
      },
      onHover: (context: any, el: any) => {
        context.native.target.style.cursor = el[0]
          ? cssObj.cursor.pointer
          : cssObj.cursor.default;
        context.chart.update();
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          mode: 'index',
          position: 'nearest',
          external: this.externalTooltipHandler,
        },
      },
      scales: this.getScales(this.units.length),
    };

    const config = {
      type: 'line',
      data: {
        datasets: this.getDatasets(),
      },
      options: options,
      plugins: [
        {
          afterDraw: (chart: any) => {
            //  mouse hover vertical line
            if (chart.tooltip?._active?.length) {
              let x = chart.tooltip._active[0].element.x;
              let yAxis = chart.scales['y-axis-0'];
              let ctx = chart.ctx;
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, yAxis.top);
              ctx.lineTo(x, yAxis.bottom);
              ctx.lineWidth = 1;
              ctx.strokeStyle = cssObj.colors.lightGray;
              ctx.stroke();
            }
          },
          beforeEvent(chart: any, args: any) {
            const event = args.event;

            if (event.type === 'mousemove') {
              const points = chart.getElementsAtEventForMode(
                event,
                'nearest',
                { intersect: true },
                true
              );

              const colorArray: any[] = [];
              let hoveredAny = false;

              if (points[0]) {
                const dataset = points[0].datasetIndex;
                const datapoint = points[0].index;
                const borderColor =
                  chart.data.datasets[dataset].borderColor[datapoint];

                for (
                  let i = 0;
                  i < chart.data.datasets[dataset].borderColor.length;
                  i++
                ) {
                  if (datapoint === i) {
                    hoveredAny = true;
                    colorArray.push(borderColor);
                  } else {
                    colorArray.push('#666');
                  }
                }
              }

              chart.config.options.scales.x.ticks.color = hoveredAny
                ? colorArray
                : '#666';
            }
          },
        },
      ],
    };

    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    assert(`2d context not supported or canvas already initialized`, !!ctx);

    this.chart = new Chart(ctx, config as any);
    this.isChartInit = true;
  }

  private updateChartDatasets() {
    this.chart.data.datasets = this.getDatasets();
    this.chart.options.scales = this.getScales(this.units.length);
    this.chart.update();
  }

  @action
  updateCurrentActiveToggle(index: number) {
    this.resetDatasetHiddenStatus();
    this.currentActiveToggle = index;

    if (index !== undefined) {
      this.hideDatasetExceptFor(index);
    }
  }

  private resetDatasetHiddenStatus() {
    this.chart.data.datasets.forEach(
      (dataset: any) => (dataset.hidden = false)
    );
    this.chart.update();
  }

  private hideDatasetExceptFor(exceptForIndex: number) {
    this.chart.data.datasets.forEach((dataset: any, index: any) => {
      if (index !== exceptForIndex) {
        dataset.hidden = true;
      }
    });
    this.chart.update();
  }

  @cached
  get filters() {
    const {
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    } = this.args.filters || {};

    return {
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    };
  }

  @action
  updateFilters(filters: PlantAssetMonitoringFilters) {
    const {
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    } = filters;

    this.args.updateFilters({
      dateRange,
      timeRange,
      plantAssetId,
      sensorCategories,
      sensorNames,
    });

    if (this.hasAllRequiredData) {
      this.loadData(filters);
    }
  }

  @cached
  get defaultTimeRange() {
    const { dayStartTime } = this.settings.locationSettings;
    const end = getEndTimeIsoString(dayStartTime);

    return { start: dayStartTime, end };
  }

  get hasAllRequiredData() {
    return Boolean(
      this.args.filters?.dateRange?.start && this.args.filters?.dateRange?.end
    );
  }
}
